import faker from "faker";

export const options = {
  type: "bar",
  responsive: true,
  maintainAspectRatio: false,
  elements: {
    point: {
      radius: 18,
    },
    line: {
      borderColor: "green",
    },
    bar: {
      borderColor: "green",
    },
  },
  plugins: {
    tooltip: {
      callbacks: {
        title: function (context) {
          if (context[0] && context[0].raw !== undefined) {
            const value = context[0].raw;
            const formattedValue = convertToHHMMSSFormat(value);
            return `${context[0].dataset.label}: ${formattedValue}`;
          }
          return '';
        },
        label: function (context) {
          const value = context.raw;
          const formattedValue = convertToHHMMSSFormat(value);
          return `${context.dataset.label}: ${formattedValue}`;
        },
      },
    },
    legend: {
      display: true,
      usePointStyle: true,
      color: "rgb(255, 99, 132)",
      lineWidth: 10,
      labels: {
        boxHeight: 8,
        boxWidth: 8,
        borderRadius: 4,
        useBorderRadius: true,
      },
    },
    datalabels: {
      display: 'auto',
      color: "white",
      font: { weight: "bold" },
      formatter: (value) => { return value > 0 ? value : '' },
    },
  },
  layout: {
    borderRadius: "10px",
  },
  barThickness: 32,
  interaction: {
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        color: "#99A2AC",
      },
      title: {
        display: true,
        text: "Hours",
      },
    },
  },
  legend: { position: "top" },
  annotation: {
    annotations: [
      {
        type: "line",
        mode: "horizontal",
        scaleID: "y-axis-0",
        value: 1,
        borderColor: "red",
        borderWidth: 1,
        label: {
          enabled: false,
          content: "Test label",
        },
      },
    ],
  },
};

export const options_NPS = {
  type: "bar",
  elements: {
    point: {
      radius: 18,
    },
    line: {
      borderColor: "green",
    },
    bar: {
      borderColor: "green",
    },
  },
  plugins: {
    legend: {
      display: true,
      usePointStyle: true,
      color: "rgb(255, 99, 132)",
      lineWidth: 10,
      labels: {
        boxHeight: 8,
        boxWidth: 8,
        borderRadius: 4,
        useBorderRadius: true,
      },
    },  
    tooltip: {
      callbacks: {
        label: function (context) {
          const value = context.raw; // The raw data value
          return `${context.dataset.label}: ${convertToHHMMSSFormat(value)}`;
        },
      },
    },  
  },
  layout: {
    borderRadius: "10px",
  },
  responsive: true,
  barThickness: 32,
  interaction: {
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
      title: {
        display: true,
        text: "Month",
      },
    },
    y: {
      stacked: true,
      min: -100,
      max: 100,
      grid: {
        color: "#99A2AC",
      },
      title: {
        display: true,
        text: "NPS Score",
      },
      ticks: {
        callback: function (value) {
          return value + "%";
        },
      },
    },
  },
  legend: { position: "top" },
  annotation: {
    annotations: [
      {
        type: "line",
        mode: "horizontal",
        scaleID: "y",
        value: 0,
        borderColor: "red",
        borderWidth: 1,
        label: {
          enabled: false,
          content: "Baseline",
        },
      },
    ],
  },
};


const labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const labels_NPS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun"];

export const data_FTE = {
  labels,
  datasets: [
    {
      label: "Users",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 800 })),
      backgroundColor: "#3287A7",
      borderRadius: 7,
    },
    {
      label: "Robots",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 800 })),
      backgroundColor: "#77B8D0",
      borderRadius: 7,
    },
  ],
};


export const formatTimeToHours = (str) => {
  const timeSavedFormat = str || "00:00:00";
  let days = 0, hours, minutes, seconds;

  // Check if there's a day component
  if (timeSavedFormat.includes(" ")) {
    const [dayPart, timePart] = timeSavedFormat.split(" ");
    days = Number(dayPart); // Convert days to number
    [hours, minutes, seconds] = timePart.split(':').map(Number);
  } else {
    [hours, minutes, seconds] = timeSavedFormat.split(':').map(Number);
  }

  // Convert total time to hours
  const timeSaved = (days * 24) + hours + (minutes / 60) + (seconds / 3600);
  return timeSaved;
};

export const convertToHoursAndMinutes = (hours) => {
  const h = Math.floor(hours);                  // Get the integer part for hours
  const m = Math.round((hours - h) * 60);       // Convert the decimal part to minutes
  const s = Math.round(((hours - h) * 60 - m) * 60);      // Convert remaining decimal part to seconds
  return `${h < 0 ? 0 : h}h ${m < 0 ? 0 : m}m ${s < 0 ? 0 : s}s`;
};

export const convertToHHMMSSFormat = (hours) => {
  let h = Math.floor(hours); // Get the integer part for hours
  let m = Math.floor((hours - h) * 60); // Convert the decimal part to minutes
  let s = Math.round(((hours - h) * 60 - m) * 60); // Convert remaining decimal part to seconds

  if (s === 60) {
    s = 0;
    m += 1; 
  }

  if (m === 60) {
    m = 0;
    h += 1; 
  }

  const formattedH = `${h}h`;
  const formattedM = `${m}m`;
  const formattedS = `${s}s`;

  return `${formattedH} ${formattedM} ${formattedS}`;
};

export const convertMinutesToHHMMSSFormat = (minutes) => {
  const h = Math.floor(minutes / 60); // Get the integer part for hours
  const m = Math.floor(minutes % 60); // Get the remaining minutes
  const s = Math.round((minutes - h * 60 - m) * 60); // Convert remaining decimal part to seconds

  // Adjust for seconds rounding to 60
  if (s === 60) {
    m += 1;
    s = 0;
  }

  // Adjust for minutes rounding to 60
  if (m === 60) {
    h += 1;
    m = 0;
  }

  return `${h}h ${m}m ${s}s`;
};

String.prototype.toColor = function() {
  var colors = [
    "#126992", "#115e77", "#4fe4de", "#28c457", "#0a2a37", "#59686d", "#031927", 
    "#07495f", "#23cade", "#2353de", "#6171a1", "#7c9bf6", "#3d315b", "#0FFF95",
    "#0640eb", "#036470", "#172f69", "#124846", "#a128ff", "#708B75", "#103900",
    "#124926", "#6e49db", "#270689", "#08269c", "#45869c", "#0640eb", "#6D72C3",
    "#00fff6", "#5a1db4", "#5a0277", "#6563ab", "#0d242e", "#514F59", "#1D1128"
  ];

  var hash = 0;
  if (this.length === 0) return hash;
  for (var i = 0; i < this.length; i++) {
      hash = this.charCodeAt(i) + ((hash << 5) - hash);
      hash = hash & hash;
  }
  hash = ((hash % colors.length) + colors.length) % colors.length;
  return colors[hash];
}

export const transformDataFTE = (botsDictionary, chartUnit) => {
  const labels = [];
  const datasets = [];
  Object.entries(botsDictionary).forEach(([key, value]) => {
    const botInfo = value.data || {};
    for (let i = 11; i >= 0; i--) {
      const suffix = `${i}m_ago`;
      const month = botInfo[`name_${suffix}`];
      if (labels.indexOf(month) === -1) {
        labels.push(month);
      }
    }
    const data = [];
    for (let i = 11; i >= 0; i--) {
      const suffix = `${i}m_ago`;
      const timeSavedFormat = botInfo[`time_saved_${suffix}`] || "00:00:00";
      let timeSaved = formatTimeToHours(timeSavedFormat);
      if (chartUnit === "Minutes") {
        timeSaved = timeSaved * 60;
      } else if (chartUnit === "EUR") {
        timeSaved = timeSaved * 30;
      }
      if (Number(timeSaved) === timeSaved &&
        !Number.isInteger(timeSaved)) {
        timeSaved = timeSaved.toFixed(2);
      }
      data.push(timeSaved ? timeSaved : 0);
    }

    // const robotIndex = datasets.length;
    // const colorIndex = robotIndex % robotColorPalette.length;

    datasets.push(
      {
        label: value.robot,
        backgroundColor: value.robot.toColor(),
        borderRadius: 7,
        minBarLength: 25,
        data,
      }
    );
  });

  return {
    labels,
    datasets,
  };
};

export const transformMonthlyDataFTE = (botsDictionary, botsVisibleList) => {
  const labels = [];
  Object.entries(botsDictionary).forEach(([key, value]) => {
    const botInfo = value.data || {};
    for (let i = 0; i <= 11; i++) {
      const suffix = `${i}m_ago`;
      const month = botInfo[`name_${suffix}`];
      const timeSavedFormat = botInfo[`time_saved_${suffix}`] || "00:00:00";
      const timeSaved = formatTimeToHours(timeSavedFormat);
      let obj = labels.find(x => x.month === month);
      const index = labels.indexOf(obj);
      if (index === -1) {
        labels.push({ month, value: !botsVisibleList || botsVisibleList.indexOf(value.robot) !== -1 ? timeSaved : 0 });
      } else {
        labels[index].value += !botsVisibleList || botsVisibleList.indexOf(value.robot) !== -1 ? timeSaved : 0;
      }
    }
  });

  let year = new Date().getFullYear();
  for (const element of labels) {
    if (element.month == "December" && !labels[0].month.startsWith("Dec")) {
      year -= 1;
    }
    element.month = element.month.slice(0, 3) + ", " + year;
    if (element.value === 0) {
      element.value = '-';
    } else {
      element.value = convertToHoursAndMinutes(element.value);
    }
  }

  return labels;
};

export const transformDataNPS = (thirdFilter) => {
  const labels = thirdFilter.map((item) => item.name_ago);
  const promoters_data = thirdFilter.map((item) => item.promoter_ago);
  const detractors_data = thirdFilter.map((item) => item.detractor_ago);
  const passive_data = thirdFilter.map((item) => item.passive_ago);

  return {
    labels,
    datasets: [
      {
        label: "Promoters",
        data: promoters_data,
        backgroundColor: "#3287A7",
        borderRadius: 7,
      },
      {
        label: "Passive",
        data: passive_data,
        backgroundColor: "#77B8D0",
        borderRadius: 7,
      },
      {
        label: "Detractors",
        data: detractors_data,
        backgroundColor: "#B7EBFE",
        borderRadius: 7,
      },
    ],
  };
};


