// login.hook.js

import { useState } from "react";
import { LoginSchema } from "../../../components/input-fields/input-fields";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../provider/features/auth/auth.slice";
import { useNavigate } from "react-router-dom";
import { getMe } from "../../../provider/features/user-management/user-management.slice";

export const useLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);

  const auth = useSelector((state) => state.auth.login);

  const handleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const initialValues = {
    email: "",
    password: "",
  };

  const onSubmit = async (values) => {
    const formData = new FormData();
    formData.append("email", values.email);
    formData.append("password", values.password);

    try {
      const loginResponse = await dispatch(login({ formData }));
      const loginStatus = loginResponse.payload.status;
      const csrfToken = loginResponse.payload.data;

      if (loginStatus !== 200) {
        setErrorMessage("Invalid username or password. Please try again.");
        return;
      }

      const userDetailsResponse = await dispatch(getMe());
      const userStatus = userDetailsResponse.payload.status;
      const is2FARequired = userDetailsResponse.payload.two_fa_web_required;
      const is2FAConfigured = userDetailsResponse.payload.two_fa_web_configured;
      const requiresPasswordChange = loginResponse.payload.requiresPasswordChange;

      if (userStatus === 200) {
        localStorage.setItem("X-CSRFToken", csrfToken);
        if (is2FARequired) {
          window.location.href = is2FAConfigured ? '/2fa-verification' : '/setup-2fa';
        } else if (requiresPasswordChange) {
          window.location.href = "/change-password";
        } else {
          window.location.href = '/';
        }
      }
    } catch (error) {
      console.error("An error occurred during login:", error);
      setErrorMessage("An unexpected error occurred. Please try again later.");
    }
  };

  return {
    initialValues,
    showPassword,
    handleShowPassword,
    LoginSchema,
    onSubmit,
    auth,
    errorMessage,
  };
};
