import api from "../../../common/utils/api";

const listOrganizations = async (params) => {
  try {    
    const response = await api().get("organization/", {
      params, 
      withCredentials: true,
      headers: {
        Accept: "application/json",
        sessionid: "{{apiKey}}", 
      },
    });
        
    return response;
  } catch (error) {
    throw error;
  }
};

const organizationServices = {
  listOrganizations,
};

export default organizationServices;
