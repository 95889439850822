import { styled } from "styled-components";

export const HeaderWrapper = styled.div`
          .go-to-team-dashboard {
          align-items: center;
          display: flex;
          gap: 13px;
          background: #086a8f;
          border: 1px solid #338bc2;
          box-shadow: 0px 0px 10px rgba(75, 255, 247, 0.5);
          border-radius: 50px;
          padding: 10px 24px 10px 24px;
          color: #ffffff;
          width: 200px;
          align: right;
        }

        .alignmentDiv {
          display: grid;
          justify-items: end;
          margin-right: 21px;
        }
`;
