import * as Yup from "yup";
import { forgotPassword } from "../../../provider/features/auth/auth.slice";
import { useDispatch } from "react-redux";

function useForgotPassword() {
  const dispatch = useDispatch();
  // Validation schema
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
  });

  const handleForgotPassword = async (values, { setSubmitting }, setSubmitted, setMessage) => {
    const formData = new FormData();
    formData.append("email", values && values.email);
    const response = await dispatch(forgotPassword({ formData }));
    setSubmitting(false);
    setSubmitted(true);
    setMessage(response?.payload?.message);
  };

  return {
    ForgotPasswordSchema,
    handleForgotPassword,
  };
}

export default useForgotPassword;
