import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { listUsers } from "../../../provider/features/user-management/user-management.slice";
import { userRights } from "../../../provider/features/right/right.slice"

const useUserManagement = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [dataDisplay, setDataDisplay] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [editOpen, setEditOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [robotsListOpen, setRobotsListOpen] = useState(false);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState('active')

  // Fetch data

  useEffect(() => {
    setLoading(true);
    getUsersData();
  }, [dispatch, selectedStatus]);

  useEffect(() => {
    setDataDisplay(
      data ?
        data.filter(x => fullTextSearch(x, search)) :
        []
    );
  }, [data, search, currentPage, entriesPerPage]);

  useEffect(() => {
    setSearch("");
  }, [location.key]);

  const getUsersData = async () => {
    let response;
    const isActiveStatus = selectedStatus === 'active' ? 'True' : selectedStatus === 'inactive' ? 'False' : null;

    if (isActiveStatus !== null) {
      response = await dispatch(listUsers({ is_active: isActiveStatus }));
    } else {
      response = await dispatch(listUsers());
    }

    //const response = await dispatch(listUsers());
    const userDataPromises = response?.payload?.map(async user => {
      const rights = await dispatch(userRights(user.user_code));
      return {
        ...user,
        rights: rights?.payload,
      };
    });
    const userData = await Promise.all(userDataPromises);
    setData(userData);
    setLoading(false);
  };

  // Search function

  const fullTextSearch = (obj, searchTerm) => {
    if (!searchTerm || searchTerm === "") {
      return true;
    }

    const searchableString = Object.values(obj)
      .join(" ")
      .toLowerCase();                 // Convert to lowercase for case-insensitive search

    return searchableString.includes(searchTerm.toLowerCase());
  };

  // Add modal

  const handleModal = (arg) => {
    setAddOpen(arg);
  };

  // Edit modal

  const handleEditModal = (user) => {
    setSelectedUser(user);
    setEditOpen(true);
  };

  const closeEditModal = () => {
    setEditOpen(false);
    setSelectedUser(null);
  };

  // Delete modal

  const handleDeleteModal = (user) => {
    setSelectedUser(user);
    setDeleteOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteOpen(false);
    setSelectedUser(null);
  };

  // Robots modal

  const handleRobotsModal = (user) => {
    setSelectedUser(user);
    setRobotsListOpen(true);
  };

  const closeRobotsModal = () => {
    setSelectedUser(null);
    setRobotsListOpen(false);
  };

  // Return values

  return {
    addOpen,
    editOpen,
    selectedUser,
    deleteOpen,
    data,
    dataDisplay,
    search,
    selectedStatus,
    setSelectedStatus,
    setSearch,
    handleModal,
    handleEditModal,
    closeEditModal,
    handleDeleteModal,
    closeDeleteModal,
    handleRobotsModal,
    robotsListOpen,
    closeRobotsModal,
    currentPage,
    setCurrentPage,
    entriesPerPage,
    fullTextSearch,
    loading,
    getUsersData,
  };
};

export default useUserManagement;
