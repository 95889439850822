import { styled } from "styled-components";

export const DashboardWrapper = styled.div`
  display: grid;
  grid-template-columns: 272px calc(100% - 272px);
  transition: all .3s ease;
      
  .topDiv {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      // justify-content: center;
      .rightPart {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 26px;
        padding-left: 22px;
        gap: 33px;
        span {
          font-weight: 500;
          font-size: 20px;
          line-height: 140%;
          color: #f4f6f8;
        }
        }
      }
    }
`;
