import { styled } from 'styled-components';

export const SupportWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #2c2b41;
    color: #fff;
    padding: 40px;
    height: 84.5vh;
    box-sizing: border-box; 
    font-family: Arial, sans-serif;

    .error-text {
        color: #c81f33 !important;
        font-size: 14px !important;
        display: inline;
    }

    .mb-10 {
        margin-bottom: 20px;
    }

    mt-10 {
        margin-top: 5px;
    }

    .top-left-text {
        position: absolute;
        top: 20px;
        left: 20px;
        font-size: 18px;
        font-weight: 400;
        line-height: 150%;
        color: #d8dadf;

        h3 {
            margin-bottom: 20px; 
        }

        p {
            margin-top: 0px; 
        }
    }

    .header-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        margin-top: 100px;
        width: 100%;
    }

    .contact-text {
        margin-left: 18.5vw; 
        color: #d8dadf;
    }

    .form-text {
        margin-right: 20vw; /
        color: #d8dadf;
    }

    .content-wrapper {
        display: flex;
        flex-grow: 1; 
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        margin-bottom: 20px;
    }

    .general-contact,
    .question-form {
        background-color: #212036;
        padding: 20px;
        border-radius: 8px;
        width: 90%;
        margin-top:20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .general-contact {
        background-color: transparent;
    }

    .question-form form {
        display: flex;
        flex-direction: column;
        justify-content: space-between; /
        height: 100%; 
    }

    .question-form label {
        margin-bottom: 5px; 
        font-size: 16px;
        color: #d8dadf;
    }   

    .contact-details {
        text-align: center;
    }

    .contact-details p,
    .contact-details a {
        font-size: 16px;
        color: #ccc;
    }

    .contact-details a:hover {
        color: #fff;
        text-decoration: underline;
    }

    .logo img {
        width: 220px;
        height: 120px;
        margin-bottom: 10px;
    }

    .contact-details p {
        font-weight: 400;
        font-size: 18px;
        padding-bottom: 5px;
        color: #d8dadf;
    }

    .question-form input,
    .question-form textarea {
        background-color: #2a2e4a;
        border: none;
        padding: 8px;
        border-radius: 4px;
        color: #ffffff;
        font-size: 14px;
    }

    .question-form textarea {
        height: 320px;
        resize: none;
    }

    .submit-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #086a8f;
        border: 1px solid #338bc2;
        box-shadow: 0px 0px 10px rgba(75, 255, 247, 0.5);
        border-radius: 50px;
        padding: 16px 34.5px;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #ffffff;
        height: 32px;
        width: fit-content;
        margin: 20px auto;
    }

    .question-form button:hover {
        background-color: #138496;
    }
`;
