import React, { useState } from "react";
import AssignRobotModal from "../assignRobotModal/assignRobotModal";
import { MoreVertWrapper } from "./style";

const MoreVertModal = ({ action }) => {
  const [open, setOpen] = useState(false);

  const handleAssignModal = (arg) => {
    setOpen(arg);
    open ? action(true) : action(false);
  };
  return (
    <MoreVertWrapper>
      {open && <AssignRobotModal show={true} action={handleAssignModal} />}

      <div className="more">
        <button className="moreContent" onClick={() => handleAssignModal(true)}>
          <img src="assets/images/table/assignSeat.svg" alt="" />
          Assign
        </button>
        <button className="moreContent">
          <img src="assets/images/auth/eye.svg" alt="" />
          View
        </button>
        <button className="moreContent">
          <img src="assets/images/table/delete.svg" alt="" />
          Delete
        </button>
      </div>
    </MoreVertWrapper>
  );
};

export default MoreVertModal;
