
const cardDetail = [
  {
    id: "totalTimeSavedCard",
    heading: "Total Time Saved",
    longHeading: "Total Time Saved (in EUR)",
    field: "rolling_time_saved",
    changeField: "rolling_time_saved_change",
    navigateTo: "targetRef",
    arrow: "/assets/images/dashboard/arrow.png",
    downArrow: "/assets/images/dashboard/down-arrow.svg",
    icon: "/assets/images/dashboard/time-monitoring.svg",
    showToggle: true,
    info: "This KPI shows the total time saved summed over all the available robots for this month (HH:MM:SS). The total time saved is based on the usage of the robot. Please check the user manual of each individual robot to check how the saved time is calculated.",
    info2: "This KPI shows the total time saved expressed in Euros. It is calculated by summing the total time saved over all the available robots for this month multiplied by the average cost of €30 per hour. The total time saved is based on the usage of the robot. Please check the user manual of each individual robot to check how the saved time is calculated."
  },
  {
    id: "usersThisMonthCard",
    heading: "Users This Month",
    field: "rolling_user_no",
    changeField: "rolling_active_user_no_change",
    navigateTo: "/user-management",
    arrow: "/assets/images/dashboard/arrow.png",
    downArrow: "/assets/images/dashboard/down-arrow.svg",
    icon: "/assets/images/dashboard/user-group.svg",
    displayJustForKeyUser: true,
  },
  {
    id: "robotsThisMonthCard",
    heading: "Seats This Month",
    field: "no_active_seats",
    secondField: "no_available_seats",
    changeField: "rolling_no_active_seats",
    navigateTo: "/robot-management",
    arrow: "/assets/images/dashboard/arrow.png",
    downArrow: "/assets/images/dashboard/down-arrow.svg",
    icon: "/assets/images/dashboard/robot.svg",
    displayJustForKeyUser: true,
    info: "This KPI shows the number of occupied seats vs the number of available seats. One occupied seat is the equivalent of one robot assigned to one user. The available seats are the total available seats over all robots and can be increased per robot on the Robot Management page."
  },
  {
    id: "successfulJobsThisMonthCard",
    heading: "Successful vs Total Jobs This Month",
    field: "rolling_successful_job_no",
    secondField: "rolling_job_no",
    changeField: "rolling_successful_job_no_change",
    // navigateTo: "/job-management",
    arrow: "/assets/images/dashboard/arrow.png",
    downArrow: "/assets/images/dashboard/down-arrow.svg",
    icon: "/assets/images/dashboard/succesfull-monitoring.svg",
    info: "This KPI shows the total number of successful jobs vs the total number of jobs over all robots that were executed by the users this month. Each time the robot is ran by pressing the fast forward button in the application, we register a job."
  },
];

export {
  cardDetail
};
