import React, { useRef, useState } from "react";
import Footer from "../../common/landingpage/components/footer";
import { DemoWrapper, DemoPage, MainContent } from "./style";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';

const Demo = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };

  const handlePlay = () => {
    setIsPlaying(true);
  };

  return (
    <DemoPage>
      <MainContent>
        <DemoWrapper>
          <div className="frame">
            <div>
              <p className="frameText">
                Lorem ipsum dolor sit amet consectetur. Venenatis <br />
                scelerisque phasellus est nunc. Odio in mauris arcu <br />
                malesuada maecenas. Id vitae feugiat enim id ut.
              </p>
            </div>
            <div>
              <button type="submit" className="submit-btn">
                Download Now
              </button>
            </div>
          </div>

          <div className="watchDemo">
            <span className="demoText">WATCH DEMO</span>
            <div
              className="video-container"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <video
                ref={videoRef}
                src="/assets/videos/demo/demo.mp4"
                className="demoVid"
                onClick={handlePlayPause}
                controls={false}
                onPause={handlePause}
                onPlay={handlePlay}
              />

              {!isPlaying && (
                <button className="play-button" onClick={handlePlayPause}>
                  <FontAwesomeIcon icon={faPlay} style={{ fontSize: '35px' }} />
                </button>
              )}

              {isPlaying && isHovered && (
                <button className="play-button" onClick={handlePlayPause}>
                  <FontAwesomeIcon icon={faPause} style={{ fontSize: '35px' }} />
                </button>
              )}
            </div>
          </div>
        </DemoWrapper>
      </MainContent>

      <Footer />
    </DemoPage>
  );
};

export default Demo;
