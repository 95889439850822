import { styled } from "styled-components";

export const ViewModalWrapper = styled.div`
  max-width: fit-content;
  margin-left: auto;
  margin-right: auto;
  top: 450px;
  margin-top: 200px;

  .cancel-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #212036;
    border: 1px solid #338bc2;
    box-shadow: 0px 0px 10px rgba(75, 255, 247, 0.5);
    border-radius: 50px;
    padding: 16px 34.5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #ffffff;
    height: 32px;
    width: fit-content;
    cursor: pointer;
  }

  .modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding-bottom: 24px;
    background: #2c2b41;
    border-radius: 10px;
    min-width: 500px;
    min-height: 300px; 
    overflow-y: auto; 
    overflow-x: hidden;

    .topPart {
      width: 100%;
      background: #212036;
      padding: 10px 15px; 
      display: flex;
      justify-content: space-between;
      align-items: center;

      .mt-n-40 {
        margin-top: -40px;
      }

      .modalTitle {
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: #f4f6f8;
      }

      .modalSubtitle {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #99a2ac;
      }

      .closeIcon {
        cursor: pointer;
        padding: 8px;
        background: #2c2b41;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .bottomPart {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      padding: 16px 16px 0px 16px;
      width: 100%;
      max-height: 50vh; 
      overflow-y: auto; 

      .userName {
        font-size: 16px;
        color: #f4f6f8;
        background: #2c2b41;
        padding: 10px 16px;
        border-radius: 5px;
        width: 100%;
        max-width: 400px;
        line-height: 1.5; 
      }

      .iconStyle {
        margin-left: 10px;
        cursor: pointer;
      }

        .iconStyle:hover {
          filter: invert(200%) sepia(200%) saturate(10000%) brightness(110%) contrast(110%);
        }
    }

    .buttonWrapper {
        display: flex;
        justify-content: center;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        bottom: 0;
    }
    table {
      width: 100%;
      table-layout: fixed; 
    }
  }
`;
