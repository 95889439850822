import React from "react";
import { useEffect, useState } from "react";

import { AuthWrapper } from "../style";
import Setup2FAModal from "../../../components/modal/2faModal"


const Setup2FA = () => {

	const [setupOpen, setSetupOpen] = useState(false);
	const handleModal = (arg) => {
		setSetupOpen(arg);
	};
	return (
		<>
			<div>
				{setupOpen &&
					<Setup2FAModal
						show={true}
						action={handleModal} />
				}
			</div>
			<AuthWrapper>
				<div className="setup-2fa">
					<div className="authForm">
						<div className="brand">
							<img
								src="/assets/images/auth/logo.svg"
								className="logoImg"
								alt="Logo"
							/>
							<span className="companyName">ATOMEUS</span>
						</div>
						<div className="heading">Set Up Two-Factor Authentication</div>

						<div className="content-wrapper">
							<div className="info-wrapper">
								<div className="setupText">
									<div className="infoText">
										2-factor authentication process is required for added security.
									</div>
									<div className="infoText">
										To protect your account, please follow the instructions to set up
										2-factor authentication.
									</div>
								</div>
							</div>

							<div className="twofaImgWrapper">
								<img
									height={200}
									width={200}
									src="/assets/images/auth/2fa.svg"
									className="2faImg"
									alt="2FA"
								/>
							</div>
						</div>
						<div className="setup-btn-wrapper">
							<button onClick={() => handleModal(true)} className="setup-btn">
								Get Started
							</button>
						</div>
					</div>
				</div>
			</AuthWrapper>
		</>
	);
};

export default Setup2FA;
