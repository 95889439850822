import React from "react";
import { ErrorContainer, ErrorMessage, ErrorButton } from "./style";
import Footer from "../../common/landingpage/components/footer";

const Error = () => {
  return (
    <>
      <ErrorContainer>
        <h1>404</h1>
        <ErrorMessage>Sorry, we couldn't find this page.</ErrorMessage>
        <ErrorButton href="/">Back to homepage</ErrorButton>
      </ErrorContainer>
    </>
  );
};

export default Error;
