import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import { ModalWrapper } from "../style";
import { EditModalWrapper } from "./editUserStyle";
import { UserRole } from '../../../enums/user-roles';
import { useUpdate } from "./user.hook";



const EditUserSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  noOfBots: Yup.number().min(1, "Must be at least 1").required("Required"),
  role: Yup.string()
    .oneOf([UserRole.KEY_USER, UserRole.USER], "Required")
    .required("Required"),
  status: Yup.boolean().required("Required"),
});

const EditUserModal = ({ show, action, user, getUsersData }) => {
  const {
    isLoading,
    handleSubmit,
  } = useUpdate();

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [infoUpdated, setInfoUpdated] = useState(false);

  const handleModal = (arg) => {
    action(arg);
  };

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        handleModal(false);
      }
    };

    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  });

  function getCssClassForField(touched, errors) {
    if (touched.role && !errors.role) {
      return "valid";
    } else if (touched.role && errors.role) {
      return "error";
    } else {
      return "";
    }
  }

  return (
    <Modal show={show} backdrop="static">
      <ModalWrapper>
        <EditModalWrapper>
          <div className="modal editModal">
            <div className="topPart topPartPadding">
              <div className="user">
                <div className="userIconDiv">
                  <img
                    src="/assets/images/modal/editUser.svg"
                    className="userIcon"
                    alt="Edit User"
                  />
                </div>
                <div className="details">
                  <span className="first">Edit User</span>
                  <span className="second">Update user details</span>
                </div>
              </div>
              <div className="crossIconDiv" onClick={() => handleModal(false)}>
                <img
                  src="/assets/images/modal/cross.svg"
                  className="crossIcon"
                  alt="Close"
                />
              </div>
            </div>
            {/* Form */}
            <Formik
              initialValues={{
                id: user?.user_id,
                firstName: user?.first_name,
                lastName: user?.last_name,
                role: user?.is_key_user ? UserRole.KEY_USER : UserRole.USER,
                status: user?.is_active,
              }}
              onSubmit={(values) => {
                handleSubmit(
                  {
                    user_id: values.id,
                    first_name: values.firstName,
                    last_name: values.lastName,
                    is_key_user: values.role === UserRole.KEY_USER,
                    is_active: values.status,
                  },
                  setSuccessMessage,
                  setInfoUpdated,
                  () => { handleModal(false); getUsersData(); }
                );
              }}
            >
              {({ errors, touched, values, setFieldValue }) => (
                <Form className="form_wrapper p-16 mt-14-negative mb-14-negative">
                  {/* First Name */}
                  <div className="field">
                    <label className="fieldLabels">First Name</label>
                    <Field
                      name="firstName"
                      type="text"
                      className={`text-field form-control field-disabled mb-2 ${getCssClassForField(
                        touched,
                        errors
                      )}}`}
                      placeholder="First Name"
                      disabled={true}
                    />
                    {errors.firstName && touched.firstName ? (
                      <div className="error-text">{errors.firstName}</div>
                    ) : null}
                  </div>

                  {/* Last Name */}
                  <div className="field">
                    <label className="fieldLabels">Last Name</label>
                    <Field
                      name="lastName"
                      type="text"
                      className={`text-field form-control field-disabled mb-2 ${getCssClassForField(
                        touched,
                        errors
                      )}}`}
                      placeholder="Last Name"
                      disabled={true}
                    />
                    {errors.lastName && touched.lastName ? (
                      <div className="error-text">{errors.lastName}</div>
                    ) : null}
                  </div>

                  {/* Role */}
                  <div className="field mb-2">
                    <span className="fieldLabels">Role</span>

                    <div className="role-container">
                      <Field
                        as="select"
                        name="role"
                        className={`form-select form-select-sm text-field form-control ${!values.role ? "text-muted" : ""
                          }`}
                        onClick={() => setDropdownOpen((prev) => !prev)}
                        onBlur={() => setDropdownOpen(false)}
                      >
                        <option value={UserRole.KEY_USER}>Key User</option>
                        <option value={UserRole.USER}>User</option>
                      </Field>

                      <div className="downArrowDiv">
                        <img
                          src="/assets/images/dashboard/arrow-down.svg"
                          className={`downArrowIcon ${isDropdownOpen ? "rotate" : ""}`}
                          alt="Arrow Icon"
                        />
                      </div>
                    </div>
                    {errors.role && touched.role ? (
                      <div className="error-text">{errors.role}</div>
                    ) : null}
                  </div>

                  {/* Status */}
                  <div className="field">
                    <label className="fieldLabels">Status</label>
                    {values.status &&
                      <label style={{ fontSize: '13px', color: '#99a2ac' }}>
                        <img
                          src="assets/images/table/warning.svg"
                          alt="Warning"
                          className="warning-icon"
                        />
                        If the user becomes inactive, all rights will be lost and the seats on the robots will become available for other users.
                      </label>
                    }
                    <div className="switch-toggle">
                      <input
                        type="checkbox"
                        id="toggleSwitch"
                        checked={values.status}
                        onChange={() => setFieldValue("status", !values.status)}
                      />
                      <label className="toggleLabel" htmlFor="toggleSwitch">
                        <span className="toggleText">
                          {values.status ? "Active" : "Inactive"}
                        </span>
                      </label>
                    </div>
                  </div>

                  {successMessage && (
                    <div className="success-text">{successMessage}</div>
                  )}

                  {/* Actions */}
                  {!infoUpdated && (
                    <div className="bottom">
                      <button
                        type="button"
                        className="cencel-btn"
                        disabled={isLoading}
                        style={{ pointerEvents: isLoading ? "none" : "auto" }}
                        onClick={() => handleModal(false)}
                      >
                        Cancel
                      </button>

                      <button type="submit" className="submit-btn">
                        {isLoading ? "Saving..." : "Save"}
                      </button>
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </EditModalWrapper>
      </ModalWrapper>
    </Modal>
  );
};

export default EditUserModal;
