import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { LogoutWrapper } from "./style";
import { logout } from "../../../provider/features/auth/auth.slice";
import { listOrganizations } from "../../../provider/features/organization/organization.slice"; 
import ChangePasswordModal from "../changePasswordModal/changePassword";

const LogoutModal = ({ closeMenu }) => {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.userManagement.me);
  const [organizationName, setOrganizationName] = useState('');
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);

  const getOrganization = async () => {
    try {
      const userCode = me.data?.user_code;
      
      const orgCode = userCode?.split('-')[0];
      
      if (orgCode) {        
        const resultAction = await dispatch(listOrganizations({ org_code: orgCode })).unwrap();
                
        if (Array.isArray(resultAction) && resultAction.length > 0) {
          const organization = resultAction.find(org => org.org_code === orgCode);
  
          if (organization) {
            setOrganizationName(organization.org_name);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching organization data:", error);
    }
  };
  
  useEffect(() => {
    if (me.data?.user_code) {
      getOrganization();
    }
  }, [me.data?.user_code]);

  useEffect(() => {
    if (!changePasswordOpen) {
      document.body.style.removeProperty('overflow');
      document.body.style.removeProperty('padding-right');
    }
      return () => {
        document.body.style.removeProperty('overflow');
        document.body.style.removeProperty('padding-right');    };
  }, [changePasswordOpen]);

  async function handleLogout() {
    await dispatch(logout());
    window.location.href = '/login';
    localStorage.removeItem("X-CSRFToken");
    localStorage.removeItem("X-ATMS-Validation");
  }

  const handleChangePasswordModal = () => {
    closeMenu();
    setChangePasswordOpen(true);
  };

  const closeChangePasswordModal = () => {
    setChangePasswordOpen(false);
  };

  return (
    <LogoutWrapper>
      <div>
        {changePasswordOpen && (
          <ChangePasswordModal
            show={true}
            action={closeChangePasswordModal}
            data={me.data}
          />
        )}
      </div>
      <div className="more">
        <button className="moreContent" onClick={handleChangePasswordModal}>
          <img src="assets/images/dashboard/user-logout.svg" alt="User Logout Icon" className="icon" />
          <span className="modalText">
          {me.data?.first_name} {me.data?.last_name}
          </span>
        </button>
        {/* <div className="moreContent">
          <img src="assets/images/dashboard/company.svg" alt="Company Icon" className="icon" />
          <span className="modalText">
            {organizationName} 
          </span>
        </div> */}
        <div className="separator">

        </div>
        <button className="moreContent" onClick={handleLogout}>
          <img src="assets/images/header/logout.svg" alt="" />
          <span className="modalText">
            Logout
          </span>
        </button>
      </div>
    </LogoutWrapper>
  );
};

export default LogoutModal;
