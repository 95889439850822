import api from "../../../common/utils/api";

const userRights = async (user_code) => {
  try {
		const response = await api().get(`right/?search=${user_code}`, {
			withCredentials: true,
		});

		const filteredRights = response.data.filter((right) => {
			const effectiveUntil = new Date(right.effective_until);
			return effectiveUntil > new Date();
		});

		return filteredRights;
	} catch (error) {
		throw error;
	}};

const botRights = async (bot_id) => {
  try {
		const response = await api().get(`right/?bot=${bot_id}`, {
			withCredentials: true,
		});

		const filteredRights = response.data.filter((right) => {
			const effectiveUntil = new Date(right.effective_until);
			return effectiveUntil > new Date();
		});

		return filteredRights;
	} 
  catch (error) {
		throw error;
	}
}

const addRight = async (bot_id, user_id) => {
  const formData = new FormData();
  formData.append("bot", bot_id);
  formData.append("user", user_id);
  const response = await api().post(`right/add/`, formData, { withCredentials: true, });
  return response;
}

const listRights = async (params) => {
  try {
    const response = await api().get("right/", {
      params,
      withCredentials: true,
    });
    
    const filteredRights = response.data.filter(right => {
      const effectiveUntil = new Date(right.effective_until);
      return effectiveUntil > new Date();
    });

    return filteredRights;  
  } catch(error) {
    throw error;
  }
};

const deleteRight = async (id) => {
  try {
    const response = await api().delete(`right/${id}/`);
    return response;
  } catch(error) {
    throw error;
  }
}

const rightServices = {
  userRights,
  botRights,
  addRight,
  listRights,
  deleteRight,
};

export default rightServices;
