import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "notistack";

import { BotModalWrapper } from "../style2";
import { listUsers } from "../../../provider/features/user-management/user-management.slice";
import { botRights, addRight } from "../../../provider/features/right/right.slice";
import SeatRequestModal from "../confirmationModal/seatRequestModal";

const AssignRobotModal = ({ show, action, bot }) => {
  const [allUsers, setAllUsers] = useState([]);
  const [showSeatsWarning, setShowSeatsWarning] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    loadUsers();

    async function loadUsers() {
      const users = await dispatch(listUsers());
      const currentBotRights = await dispatch(botRights(bot?.bot_id));
      if (!bot?.seats || currentBotRights?.payload?.length >= bot?.seats) {
        setShowSeatsWarning(true);
      }
      const filteredUsers = users?.payload?.filter(u => !currentBotRights?.payload?.some(r => r.user === u.user_id));
      setAllUsers(filteredUsers);
      setSelectedUserId(filteredUsers[0]?.user_id);
    }
  }, [dispatch, bot]);

  const handleSubmit = async () => {
    if (showSeatsWarning) {
      setShowConfirmation(true);
    } else {
      await assignRobot(bot, selectedUserId);
    }
  };

  const handleCloseModal = () => {
    action(false);
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const assignRobot = async (bot, selectedUserId) => {
    const response = await dispatch(addRight({ bot_id: bot?.bot_id, user_id: selectedUserId }));
	
    if (response?.meta?.requestStatus === "fulfilled") {
      enqueueSnackbar("The robot has been assigned", { variant: "success", });
      handleCloseModal();
    } else {
      enqueueSnackbar("Users can no longer be assigned to this robot. Maximum number of available seats have been reached", { variant: "error", });
    }
  }


  return (
		<>
			{/* Modal Content */}
			{!showConfirmation && (
				<Modal show={show} >
					<BotModalWrapper>					
						<div className="modal">
							{/* Header */}
							<div className="topPart">
								<div className="user">
									<div className="userIconDiv">
										<img
											src="/assets/images/table/assignSeat.svg"
											className="userIcon"
											alt=""
										/>
									</div>
									<div className="details">
										<span className="first">Assign Robot</span>
										<span className="second">Enter details of users</span>
									</div>
								</div>
								<div className="crossIconDiv" onClick={handleCloseModal}>
									<img
										src="/assets/images/modal/cross.svg"
										className="crossIcon"
										alt="close"
									/>
								</div>
							</div>

							{/* Content */}
							<div className="form_wrapper">
								<div className="field mb-2">
									<span className="fieldLabels">Assign to</span>

									{/* Users dropdown */}
									<select
										className="form-select form-select-sm text-field"
										aria-label=".form-select-sm example"
										onChange={(e) => setSelectedUserId(e.target.value)}
										onClick={() => setDropdownOpen((prev) => !prev)}
										onBlur={() => setDropdownOpen(false)}
									>
										{allUsers?.length === 0 && (
											<option selected>Select user</option>
										)}
										{allUsers?.map((user) => (
											<option value={user?.user_id}>
												{user?.first_name} {user?.last_name}
											</option>
										))}
									</select>
									<div className="downArrowDiv">
										<img
											src="/assets/images/dashboard/arrow-down.svg"
											className={`downArrowIcon ${
												isDropdownOpen ? "rotate" : ""
											}`}
											alt="Arrow Icon"
										/>
									</div>
								</div>

								{/* Actions */}
								<div className="bottom">
									<button className="cencel-btn" onClick={handleCloseModal}>
										Cancel
									</button>
									<button className="submit-btn" onClick={handleSubmit}>
										Assign Robot
									</button>
								</div>
							</div>
						</div>
					</BotModalWrapper>
				</Modal>
			)}
			{/* Confirmation modal */}
			{showConfirmation && (
				<SeatRequestModal
					show={showConfirmation}
					title={"Assign Robot"}
					message={`Please note that the seat limit for this bot has been reached.
					
					To purchase an additional seat, the cost is €${bot?.cost_per_seat} per month.
					
					If you wish to expand the number of available seats, you can click the button below to send us your request.`}
					data={{
						botCode: bot?.bot_code,
						botSeatLimit: bot?.seats,
						botSeatCost: bot?.cost_per_seat,
					}}
					onCancel={handleCloseConfirmation}
          			closeParentModal={handleCloseModal}
				></SeatRequestModal>
			)}
		</>
	);
};

export default AssignRobotModal;