import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { registerUser } from "../../../provider/features/auth/auth.slice";

export const useRegister = () => {
  const { signedpk } = useParams();
  const dispatch = useDispatch();
  const { isLoading, isSuccess, isError, message } = useSelector((state) => state.auth.register);


  const handleSubmit = async (values) => {
    try {
      await dispatch(registerUser({ signedpk, formData: values })).unwrap();
    } catch (error) {
      console.error("Registration error:", error);
    }
  };

  return {
    signedpk,
    isLoading,
    isSuccess,
    isError,
    message,
    handleSubmit,
  };
};