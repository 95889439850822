import { styled } from "styled-components";

export const EditModalWrapper = styled.div`
  .editModal {
    top: 5vh;
    height: 80vh;
    max-height: 90vh;
    overflow-y: auto;
    padding: 0;
    box-sizing: border-box;
  }

  .topPartPadding {
    padding: 20px 20px 34px 20px !important;
  }

  .field-disabled {
    color: #99a2ac !important;
  }

  .switch-toggle {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch-toggle input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .toggleLabel {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
  }

  .toggleLabel::before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 0px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + .toggleLabel {
    background-color: #086a8f;
  }

  input:checked + .toggleLabel::before {
    transform: translateX(35px);
  }

  .toggleText {
    position: absolute;
    left: 70px;
    top: 2px;
    font-size: 16px;
    color: white;
  }

  input:checked + .toggleLabel + .toggleText {
    color: white;
  }

  .text-field,
  .form-select,
  input[type="text"],
  input[type="number"] {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    height: 48px;
    padding: 10px 12px; 
    font-size: 16px; 
  }

  .role-container {
    display: flex;
    align-items: center;
    position: relative;
  }

  .downArrowDiv {
    position: absolute;
    right: 0px !important; 
    top: 50%; 
    transform: translateY(-50%);
    background: #086a8f;
    border-radius: 5px;
    height: 40px;
    width: 40px;
    pointer-events: none;

    .downArrowIcon {
      height: 20px;
      width: 20px;
      pointer-events: none;
      transition: transform 0.3s ease;
    }
  }

  .downArrowIcon.rotate {
    transform: rotate(180deg);
  }

  .form_wrapper {
    max-height: 65vh;
    overflow-y: auto;
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 24px;
    background: #212036;
    border-radius: 5px;
  }
`;
