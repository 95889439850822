import api from "../../../common/utils/api";

const getCardData = async (dashboardType) => {
  try {
    const response = await api().get(`/dashboard/${dashboardType}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching user:", error);
    throw error;
  }
};

const getBotData = async (dashboardType, bot_id) => {
  try {
    const response = await api().get(`/dashboard/${dashboardType}${bot_id}/`);
    return response.data;
  } catch (error) {
    console.error("Error fetching user:", error);
    throw error;
  }
};

const dashboardService = {
  getCardData,
  getBotData
};

export default dashboardService;
