import { styled } from "styled-components";

export const LogoutWrapper = styled.div`
  position: relative;
  width: auto;
  min-width: 200px;
  background: #2c2b41;
  border: 1px solid #3b3e4e;
  border-radius: 10px;

  .more {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 16px;
    gap: 16px;

    .separator {
      width: 140%;
      height: 1px;
      background-color: gray;
      margin-left: -16px; 
    }

    .modalText {
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      text-align: right;
      color: #d8dadf;
    }

    .icon {
      width: 20px;
      height: 20px;
    }

    .me-12 {
      margin-right: 12px;
    }
    .moreContent {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #e0e0e0;
      display: flex;
      gap: 11.44px;
      width: 100%;
      padding-bottom: 5px;
      background: #2c2b41;
      align-items: center;
    }
  }
`;
