import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import dashboardService from "./dashboard.service";
import userManagementServices from "../user-management/user-management.service"; 
import robotManagementServices from '../robot-management/robot-management.service';
import rightServices from "../../../provider/features/right/right.service";

const initialState = {
  getUser: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
};

export const getCardData = createAsyncThunk(
  "dashboard/cardData",
  async ({ urlSuffix, successCallBack }, thunkAPI) => {
    try {
      const response = {};
      response.cardData = await dashboardService.getCardData(urlSuffix);
      response.listUsers = await userManagementServices.listUsers();
      response.listRobots = await robotManagementServices.listRobots();
      response.listRobots = response.listRobots?.data || [];
      const updatedRobots = await Promise.all(
        response.listRobots.map(async (robot) => {
          const numberOfUsers = await rightServices.listRights({
            search: robot.bot_code,
          });
          return { ...robot, numberOfUsers: numberOfUsers.length || 0 };
        })
      );
      response.listRobots = updatedRobots;
      if (response.cardData) {
        successCallBack(response);
        return response;
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

export const getChartData = createAsyncThunk(
  "dashboard/chartData",
  async ({ urlSuffix, successCallBack }, thunkAPI) => {
    try {
      const response = await robotManagementServices.listRobots();
      if (!response) {
        thunkAPI.rejectWithValue(response);
      }
      const bots =  response?.data;
      const botsDictionary = {};
      for (let i = 0; i < bots.length; i++) {
        const botResponse = await dashboardService.getBotData(urlSuffix, bots[i].bot_id);
        botsDictionary[bots[i].bot_id] = botResponse;
      }
      return successCallBack(botsDictionary);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    reset: (state) => {
      state.getUser = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCardData.pending, (state) => {
        state.getUser.isLoading = true;
        state.getUser.message = "";
        state.getUser.isError = false;
        state.getUser.isSuccess = false;
        state.getUser.data = null;
      })
      .addCase(getCardData.fulfilled, (state, action) => {
        state.getUser.isLoading = false;
        state.getUser.isSuccess = true;
        state.getUser.data = action.payload;
      })
      .addCase(getCardData.rejected, (state, action) => {
        state.getUser.message = action.payload.message;
        state.getUser.isLoading = false;
        state.getUser.isError = true;
        state.getUser.data = null;
      })
      .addCase(getChartData.pending, (state) => {
        state.getUser.isLoading = true;
        state.getUser.message = "";
        state.getUser.isError = false;
        state.getUser.isSuccess = false;
        state.getUser.data = null;
      })
      .addCase(getChartData.fulfilled, (state, action) => {
        state.getUser.isLoading = false;
        state.getUser.isSuccess = true;
        state.getUser.data = action.payload;
      })
      .addCase(getChartData.rejected, (state, action) => {
        state.getUser.message = action.payload.message;
        state.getUser.isLoading = false;
        state.getUser.isError = true;
        state.getUser.data = null;
      });
  },
});


export const { reset } = dashboardSlice.actions;

export default dashboardSlice.reducer;
