import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../provider/features/user-management/user-management.slice";

export const useUpdate = () => {
    const dispatch = useDispatch();
    const { isLoading, isSuccess, isError, message } = useSelector((state) => state.userManagement.updateUser);
  
  
    const handleSubmit = async (payload, setSuccessMessage, setInfoUpdated, callbackSuccess) => {
        const resultAction = await dispatch(updateUser(payload));

        if (updateUser.fulfilled.match(resultAction)) {
            setSuccessMessage("User info successfully updated!");
            setInfoUpdated(true);
            setTimeout(() => {
              callbackSuccess();
            }, 1500);
          }
    };
  
    return {
      isLoading,
      isSuccess,
      isError,
      message,
      handleSubmit,
    };
};