import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import robotManagementServices from './robot-management.service';

const initialState = {
  allRobots: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  getRobot: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
};

export const listRobots = createAsyncThunk(
  "robotManagement/getAllRobots",
  async (thunkAPI) => {
    try {
      const response = await robotManagementServices.listRobots();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

export const getRobot = createAsyncThunk(
  "robotManagement/getRobot",
  async (id, thunkAPI) => {
    try {
      const response = await robotManagementServices.getRobot(id);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

export const robotManagementSlice = createSlice({
  name: "robotsManagementSlice",
  initialState,
  reducers: {
    reset: (state) => {
      state.allRobots = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // getAllRobots
      .addCase(listRobots.pending, (state) => {
        state.allUsers.isLoading = true;
        state.allUsers.message = "";
        state.allUsers.isError = false;
        state.allUsers.isSuccess = false;
        state.allUsers.data = null;
      })
      .addCase(listRobots.fulfilled, (state, action) => {
        state.allUsers.isLoading = false;
        state.allUsers.isSuccess = true;
        state.allUsers.data = action.payload;
      })
      .addCase(listRobots.rejected, (state, action) => {
        state.allUsers.message = action.payload;
        state.allUsers.isLoading = false;
        state.allUsers.isError = true;
        state.allUsers.data = null;
      })
      // getRobot
      .addCase(getRobot.pending, (state) => {
        state.getRobot.isLoading = true;
        state.getRobot.message = "";
        state.getRobot.isError = false;
        state.getRobot.isSuccess = false;
        state.getRobot.data = null;
      })
      .addCase(getRobot.fulfilled, (state, action) => {
        state.getRobot.isLoading = false;
        state.getRobot.isSuccess = true;
        state.getRobot.data = action.payload;
      })
      .addCase(getRobot.rejected, (state, action) => {
        state.getRobot.message = action.payload;
        state.getRobot.isLoading = false;
        state.getRobot.isError = true;
        state.getRobot.data = null;
      });
  },
});

export default robotManagementServices.reducer;