import { styled } from "styled-components";

export const ChangePasswordWrapper = styled.div `
.switch-toggle {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 34px;
    }

    .password-input {
      position: relative;
    }
    
    .eye-icon {
      position: absolute;
      right: 10px; /* Adjust as needed to fit your input padding */
      top: 50%;
      transform: translateY(-80%);
      cursor: pointer;
    }

    .switch-toggle input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .toggleLabel {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      transition: 0.4s;
      border-radius: 34px;
    }

    .toggleLabel::before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 0px;
      background-color: white;
      transition: 0.4s;
      border-radius: 50%;
    }

    input:checked + .toggleLabel {
      background-color: #086a8f;
    }

    input:checked + .toggleLabel::before {
      transform: translateX(35px);
    }

    .toggleText {
      position: absolute;
      left: 70px;
      top: 2px;
      font-size: 16px;
      color: white;
    }

    input:checked + .toggleLabel + .toggleText {
      color: white;
    }
`;

