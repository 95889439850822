import React from "react";
import { useEffect, useState } from "react";
import { AuthWrapper } from "../style";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { validate2FACode, saveQRToken } from "../../../provider/features/auth/auth.slice";
import { v4 as uuidv4 } from 'uuid';

const Verification2FAScreen = () => {

	const [setupOpen, setSetupOpen] = useState(false);
	const [codeDigits, setCodeDigits] = useState(["", "", "", "", "", ""]);
	const [successMessage, setSuccessMessage] = useState("");
	const [failureMessage, setFailureMessage] = useState("");
	const navigate = useNavigate();

	const dispatch = useDispatch();
	const handleModal = (arg) => {
		setSetupOpen(arg);
	};

	const handleInputChange = (index, value) => {
		if (isNaN(value) || value.length > 1) return;
		const updatedDigits = [...codeDigits];
		updatedDigits[index] = value;
		setCodeDigits(updatedDigits);

		if (value && index < 5) {
			document.getElementById(`digit-${index + 1}`).focus();
		}
	};

	const handleBackspace = (index) => {
		if (codeDigits[index] === "" && index > 0) {
			document.getElementById(`digit-${index - 1}`).focus();
		}
	};

	const handleVerifyAndActivate = async () => {
		const verificationCode = codeDigits.join("");
		const resultAction = await dispatch(validate2FACode({ code: verificationCode }));
		if (validate2FACode.fulfilled.match(resultAction)) {
			setFailureMessage("");
			setSuccessMessage("Code was validated!");
			const response = await dispatch(saveQRToken());
			const guid = uuidv4();
			// Store the generated GUID in local storage
			localStorage.setItem('X-ATMS-Validation', guid);
			
			setTimeout(() => {
				window.location.href = '/';
			}, 2000);
		} else {
			setFailureMessage("Incorrect 2FA code!");
		}
	};

	return (
		<>
			<AuthWrapper>
				<div className="setup-2fa">
					<div className="authForm" style={{ gap: 0 }}>
						<div className="brand">
							<img
								src="/assets/images/auth/logo.svg"
								className="logoImg"
								alt="Logo"
							/>
							<span className="companyName">ATOMEUS</span>
						</div>
						<div className="heading" style={{ marginBottom: 10 }}>
							Enter Two-Factor Authentication Code
						</div>

						<div className="content-wrapper">
							<div
								className="info-wrapper"
								style={{ marginRight: 0, marginTop: 0 }}
							>
								<div className="setupText">
									<div className="infoText" style={{marginTop: 0 }}>
										Enter the six-digit code from your authentication
										application.
									</div>
								</div>
							</div>

							<div className="twofaImgWrapper">
								<img
									height={200}
									width={200}
									src="/assets/images/auth/2fa.svg"
									className="2faImg"
									alt="2FA"
								/>
							</div>
							<div
								className="verificationCodeInputContainer"
								style={{ marginTop: 30, marginBottom: 20 }}
							>
								{codeDigits.map((digit, index) => (
									<input
										key={index}
										id={`digit-${index}`}
										type="text"
										value={digit}
										onChange={(e) => handleInputChange(index, e.target.value)}
										onKeyDown={(e) => {
											if (e.key === "Backspace") handleBackspace(index);
										}}
										maxLength="1"
										className="verificationDigitInput"
									/>
								))}
							</div>
						</div>
						<div className="twoFAmessages" style={{height: 60}}>
							{successMessage && (
								<div className="success-text">{successMessage}</div>
							)}
							{failureMessage && (
								<div className="error-text">{failureMessage}</div>
							)}
						</div>
						<div className="setup-btn-wrapper" style={{marginTop: 0 }}>
							<button
								onClick={() => handleVerifyAndActivate()}
								className="setup-btn"
							>
								Verify code
							</button>
						</div>
						<div className="resetWrapper">
							<hr className="line" />
							<div className="resetText">
								No longer have access to your authenticator app?
							</div>
							<div className="resetLink">
								<a
									href="https://www.atomeus.nl/technology/contact/"
									target="_blank"
									rel="noopener noreferrer"
									className="link"
								>
									Submit a ticket to us, and we will assist you with the process.
								</a>
							</div>
						</div>
					</div>
				</div>
			</AuthWrapper>
		</>
	);
};

export default Verification2FAScreen;
