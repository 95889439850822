import api from "../../../common/utils/api";

const listVersions = async (params) => {
    try {
        const response = await api().get("version/", {
            params,  
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};


const versionService = {
    listVersions,
};

export default versionService;