import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCardData, getChartData } from "../../../provider/features/dashboard/dashboard.slice";
import { transformDataFTE, transformDataNPS, formatTimeToHours, transformMonthlyDataFTE, convertToHHMMSSFormat, convertMinutesToHHMMSSFormat } from "./chart-constant";
import { options } from "./chart-constant";
import { getOrganization } from "../../../provider/features/organization/organization.slice";
import { getUser } from "../../../provider/features/user-management/user-management.slice";

export const useDashboard = ({ user_id, bot_id }) => {
  const dispatch = useDispatch();
  const [cardData, setCardData] = useState([]);
  const [chartData, setChartData] = useState({});
  const [loadingCard, setLoadingCard] = useState(true);
  const [loadingChart, setLoadingChart] = useState(true);
  const [loadingHeader, setLoadingHeader] = useState(true);
  const me = useSelector((state) => state.userManagement.me);
  const [dataFTE, setDataFTE] = useState({ labels: [], datasets: [] });
  const [monthlyDataFTE, setMonthlyDataFTE] = useState([]);
  const [dataNPS, setDataNPS] = useState({ labels: [], datasets: [] });
  const [cumulativeNPS, setCumulativeNPS] = useState(0);
  const [displayTotalTimeSavedInEUR, setDisplayTotalTimeSavedInEUR] = useState(false);
  const [chartUnit, setChartUnit] = useState("Hours");
  const [chartOptions, setChartOptions] = useState(options);
  const [dashboardHeaderText, setDashboardHeaderText] = useState(undefined);
  const [visibleDatasets, setVisibleDatasets] = useState(undefined);
  const [isKeyUser, setIsKeyUser] = useState(false);

  useEffect(() => {
    setDataNPS(
      cardData.thirdFilter
        ? transformDataNPS(cardData.thirdFilter)
        : { labels: [], datasets: [] }
    );
    if (cardData.fourthFilter) {
      setCumulativeNPS(cardData.fourthFilter.cumulative_nps);
    }
  }, [cardData]);

  useEffect(() => {
    setDataFTE(
      chartData
        ? transformDataFTE(chartData, chartUnit)
        : { labels: [], datasets: [] }
    );
    const newOptions = JSON.parse(JSON.stringify(options));
    newOptions.scales.y.title.text = chartUnit;

    if(chartUnit === 'Hours') {
      newOptions.plugins.tooltip = {
        callbacks: {
          label: function (context) {
            const value = context.raw;
            if (value === null || value === 0) {
              return "";
            }
            return `${context.dataset.label}: ${convertToHHMMSSFormat(value)}`;
          }
        }
      };
    } else if(chartUnit === 'Minutes') {
      newOptions.plugins.tooltip = {
        callbacks: {
          label: function (context) {
            const value = context.raw;
            if (value === null || value === 0) {
              return "";
            }
            return `${context.dataset.label}: ${convertMinutesToHHMMSSFormat(value)}`;
          }
        }
      };
    } else {
      newOptions.plugins.tooltip = {
        callbacks: {
          label: function (context) {
            const value = context.raw;
            if (value === null || value === 0) {
              return "";
            }
            return `${context.dataset.label}: ${value}€`;
          }
        }
      }
    }

    newOptions.plugins.legend.onClick = (e, legendItem, legend) => {
      const index = legendItem.datasetIndex;
      const chart = legend.chart;

      // Toggle dataset visibility
      chart.isDatasetVisible(index)
        ? chart.hide(index)
        : chart.show(index);

      // Update the list of visible datasets
      const updatedVisibleDatasets = chart.data.datasets
        .filter((_, i) => chart.isDatasetVisible(i))
        .map((dataset) => dataset.label);

      setVisibleDatasets(updatedVisibleDatasets);
    };
    setChartOptions(newOptions);
    setLoadingChart(false);
  }, [chartData, chartUnit]);

  useEffect(() => {
    setMonthlyDataFTE(
      chartData
        ? transformMonthlyDataFTE(chartData, visibleDatasets)
        : []
    );
  }, [chartData, visibleDatasets]);

  useEffect(() => {
    if (!me.data) {
      return;
    }
    setLoadingCard(true);
    setLoadingChart(true);
    let getCardDataUrlSuffix = "";
    if (me.data?.is_key_user) {
      if (!user_id && !bot_id) {
        getCardDataUrlSuffix = "team/";
      } else {
        getCardDataUrlSuffix = "user/";
        if (user_id) {
          getCardDataUrlSuffix = getCardDataUrlSuffix + `${user_id}/`;
          if (bot_id) {
            getCardDataUrlSuffix = getCardDataUrlSuffix + `${bot_id}/`;
          }
        }
      }
    } else {
      getCardDataUrlSuffix = "personal/";
      if (bot_id) {
        getCardDataUrlSuffix = getCardDataUrlSuffix + `${bot_id}/`;
      }
    }
    let getChartDataUrlSuffix = "";
    if (me.data?.is_key_user) {
      getChartDataUrlSuffix = `team/`;
      if (user_id) {
        getChartDataUrlSuffix = `user/${user_id}/`;
      }
    } else {
      getChartDataUrlSuffix = "personal/";
    }
    dispatch(getChartData({ urlSuffix: getChartDataUrlSuffix, successCallBack: (data) => { setChartData(data) } }));
    dispatch(getCardData({ urlSuffix: getCardDataUrlSuffix, successCallBack: moveRouter }));
  }, [bot_id, me.data, user_id]);

  useEffect(() => {
    if (!me.data) {
      return;
    }

    setLoadingHeader(true);
    if (user_id) {
      dispatch(getUser(
        {
          user_id,
          successCallBack: (resultAction) => {
            if (resultAction?.data) {
              setDashboardHeaderText(`${resultAction.data.first_name} ${resultAction.data.last_name} Dashboard`);
              setLoadingHeader(false);
            }
          }
        })).unwrap();

    } else if (me.data?.is_key_user) {
      try {
        const userCode = me.data?.user_code;

        const orgCode = userCode?.split('-')[0];

        if (orgCode) {
          dispatch(getOrganization(
            {
              org_code: orgCode,
              successCallBack: (resultAction) => {
                if (Array.isArray(resultAction) && resultAction.length > 0) {
                  const organization = resultAction.find(org => org.org_code === orgCode);

                  if (organization) {
                    setDashboardHeaderText(`${organization.org_name} Automation Analytics`);
                    setLoadingHeader(false);
                  }
                }
              }
            })).unwrap();
        }
      } catch (error) {
        console.error("Error fetching organization data:", error);
      }
    } else {
      setDashboardHeaderText("My Dashboard");
      setLoadingHeader(false);
    }
  }, [me.data, user_id]);

  useEffect(() => {
    setIsKeyUser(me.data?.is_key_user);
  }, [me.data]);

  const moveRouter = (data) => {
    const transformFilter = transformData(data);
    setCardData(transformFilter);
    setLoadingCard(false);
  };

  const transformData = (dataForParse) => {
    const filterResponse = {
      firstFilter: {},
      secondFilter: [],
      thirdFilter: [],
      fourthFilter: {},
    };

    const filteredData = {
      "rolling_user_no": "active_user_no_this_month",
      "rolling_time_saved": "time_saved_this_month",
      "rolling_nps": "nps_this_month",
      "rolling_active_user_no_change": "rolling_active_user_no_change",
      "rolling_time_saved_change": "rolling_time_saved_change",
      "rolling_successful_job_no": "successful_job_no_this_month",
      "rolling_successful_job_no_change": "rolling_successful_job_no_change",
      "rolling_job_no": "job_no_this_month",
      "no_active_seats": "no_active_seats",
      "no_available_seats": "no_available_seats",
      "rolling_no_active_seats": "rolling_no_active_seats",
    };

    Object.entries(filteredData).forEach(([key, value]) => {
      filterResponse.firstFilter[key] = dataForParse.cardData.data[value] === null ? 0 : dataForParse.cardData.data[value];
      if (key === "rolling_time_saved") {
        filterResponse.firstFilter[key] = formatTimeToHours(filterResponse.firstFilter[key]);
        filterResponse.firstFilter[key] = convertToHHMMSSFormat(filterResponse.firstFilter[key]);
      }
    });

    for (let i = 11; i >= 0; i--) {
      const suffix = `${i}m_ago`;
      const monthData = {
        name_ago: (dataForParse.cardData.data[`name_${suffix}`] || "").substring(0, 3),
        user_ago: dataForParse.cardData.data[`user_no_${suffix}`] || 0,
        time_ago: ((dataForParse.cardData.data[`time_saved_${suffix}`] || 0) / 3600 / 160).toFixed(2),
      };
      filterResponse.secondFilter.push(monthData);

      const promoterData = {
        name_ago: (dataForParse.cardData.data[`name_${suffix}`] || "").substring(0, 3),
        promoter_ago: dataForParse.cardData.data[`promoter_no_${suffix}`] || 0,
        detractor_ago: dataForParse.cardData.data[`detractor_no_${suffix}`] || 0,
        passive_ago: dataForParse.cardData.data[`passive_no_${suffix}`] || 0,
      };
      filterResponse.thirdFilter.push(promoterData);
    }

    filterResponse.fourthFilter = {
      cumulative_nps: ((dataForParse.cardData.data.cumulative_nps || 0) + 100) / 200,
    };

    if (dataForParse.listUsers?.length > 0) {
      filterResponse.firstFilter['rolling_user_no'] = dataForParse.listUsers.filter(user => user.is_active).length;
    }
    
    return filterResponse;
  };

  const parseFormattedTime = (formattedTime) => {
    const timeRegex = /(\d+)h\s(\d+)m\s(\d+)s/;
    const match = formattedTime.match(timeRegex);
    
    if (match) {
      const hours = parseInt(match[1], 10);
      const minutes = parseInt(match[2], 10);
      const seconds = parseInt(match[3], 10);
      return hours + minutes / 60 + seconds / 3600;
    }
    
    return parseFloat(formattedTime) || 0; 
  };
  
  const formatCardInfo = (value, id, displayTotalTimeSavedInEUR) => {
    if (!displayTotalTimeSavedInEUR || id !== 'totalTimeSavedCard') {
      return value;
    }
    let returnValue = parseFormattedTime(value);
    returnValue = returnValue * 30;

    let options = {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    };
    let number = parseFloat(returnValue);
    let formattedNumber = number.toLocaleString('nl-NL', options);// Formats with a comma as the decimal separator;
    return formattedNumber; 
  };
  
  return {
    cardData,
    loadingCard,
    loadingChart,
    loadingHeader,
    dataFTE,
    dataNPS,
    cumulativeNPS,
    displayTotalTimeSavedInEUR,
    setDisplayTotalTimeSavedInEUR,
    chartUnit,
    setChartUnit,
    chartOptions,
    dashboardHeaderText,
    monthlyDataFTE,
    formatCardInfo,
    isKeyUser,
  };
};
