import { styled } from "styled-components";

export const DemoWrapper = styled.div`
  padding: 120px 130px;
  display: flex;
  flex-direction: column;
  gap: 78px;
  border-left: none;
  zoom: 90%;
  min-height: calc(100vh - 100px); 
  overflow-y: auto; 

  .frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
    gap: 24px;
    background: #212036;
    box-shadow: 0px 2px 16px rgba(75, 255, 247, 0.5);
    border-radius: 8px;
    width: 68%;
    margin: 0 auto;

    .frameText {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 32px;
      text-align: center;
      letter-spacing: 0.2px;
      color: #f4f6f8;
    }

    .submit-btn {
      background: #086a8f;
      border: 1px solid #338bc2;
      box-shadow: 0px 0px 10px rgba(75, 255, 247, 0.5);
      border-radius: 50px;
      padding: 12px 34.5px;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      color: #ffffff;
    }
  }

  .watchDemo {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 18px;
    align-items: center;

    .demoText {
      font-size: 22px;
    }

    .video-container {
      position: relative;
      display: inline-block;
    }

    .play-button {
      position: absolute;
      top: 65%;
      left: 50%;
      transition: opacity 0.2s ease;
      transform: translate(-50%, -50%);
      background-color: #086a8f; 
      color: white;
      width: 70px;
      height: 70px;
      border: none;
      border-radius: 50%; 
      padding: 15px; 
      font-size: 40px; 
      cursor: pointer;
      opacity: 0.8;
      display: flex; 
      justify-content: center;
      align-items: center;
      opacity: 1;
    }

    .triangle-icon {
      font-size: 35px; 
    }

    .demoVid {
      width: 620px;
      height: 350.61px;
      border-radius: 20px;

      @media screen and (max-width:580px){
        width: 380px;
        height: 390.61px;
      }
    }
  }
`;

export const DemoPage = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const MainContent = styled.div`
  flex-grow: 1;
`;
