import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import rightServices from './right.service';

const initialState = {
  userRights: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
  },
  botRights: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
  },
  listRights: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
  },
  addRight: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
  },
  deleteRight: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
  }
};

export const userRights = createAsyncThunk(
  'right/userRights',
  async (user_code, thunkAPI) => {
    try {
      const response = await rightServices.userRights(user_code);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

export const listRights = createAsyncThunk(
  'right/listRights',
  async (params, thunkAPI) => {
    try {
      const response = await rightServices.listRights(params);
      return response;
    } catch (error) {
      console.error("Service call failed, error:", error);
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

export const deleteRight = createAsyncThunk(
  'right/deleteRight',
  async (id, thunkAPI) => {
    try {
      const response = await rightServices.deleteRight(id);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

export const botRights = createAsyncThunk(
  'right/botRights',
  async (bot_id, thunkAPI) => {
    try {
      const response = await rightServices.botRights(bot_id);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

export const addRight = createAsyncThunk(
  'right/addRight',
  async ({ bot_id, user_id }, thunkAPI) => {
    try {
      const response = await rightServices.addRight(bot_id, user_id);
      if (!response || response?.status !== 201) {
        return thunkAPI.rejectWithValue({ payload: response.data });
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

export const rightSlice = createSlice({
  name: 'rightSlice',
  initialState,
  reducers: {
    reset: (state) => {
      state.userRights = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: '',
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // getUserRights
      .addCase(userRights.pending, (state) => {
        state.userRights.isLoading = true;
        state.userRights.message = '';
        state.userRights.isError = false;
        state.userRights.isSuccess = false;
        state.userRights.data = null;
      })
      .addCase(userRights.fulfilled, (state, action) => {
        state.userRights.isLoading = false;
        state.userRights.isSuccess = true;
        state.userRights.data = action.payload;
      })
      .addCase(userRights.rejected, (state, action) => {
        state.userRights.message = action.payload;
        state.userRights.isLoading = false;
        state.userRights.isError = true;
        state.userRights.data = null;
      })
      //listRights
      .addCase(listRights.pending, (state) => {
        state.listRights.isLoading = true;
        state.listRights.message = '';
        state.listRights.isError = false;
        state.listRights.isSuccess = false;
        state.listRights.data = null;
      })
      .addCase(listRights.fulfilled, (state, action) => {
        state.listRights.isLoading = false;
        state.listRights.isSuccess = true;
        state.listRights.data = action.payload;
      })
      .addCase(listRights.rejected, (state, action) => {
        state.listRights.message = action.payload.message;
        state.listRights.isError = true;
        state.listRights.isLoading = false
        state.listRights.data = null;
      })
      //deleteRight
      .addCase(deleteRight.pending, (state) => {
        state.deleteRight.isLoading = true;
        state.deleteRight.message = "";
        state.deleteRight.isError = false;
        state.deleteRight.isSuccess = false;
      })
      .addCase(deleteRight.fulfilled, (state, action) => {
        state.deleteRight.isLoading = false;
        state.deleteRight.isSuccess = true;
      })
      .addCase(deleteRight.rejected, (state, action) => {
        state.deleteRight.message = action.payload;
        state.deleteRight.isLoading = false;
        state.deleteRight.isError = true;
      })
      // botRights
      .addCase(botRights.pending, (state) => {
        state.botRights.isLoading = true;
        state.botRights.message = '';
        state.botRights.isError = false;
        state.botRights.isSuccess = false;
        state.botRights.data = null;
      })
      .addCase(botRights.fulfilled, (state, action) => {
        state.botRights.isLoading = false;
        state.botRights.isSuccess = true;
        state.botRights.data = action.payload;
      })
      .addCase(botRights.rejected, (state, action) => {
        state.botRights.message = action.payload;
        state.botRights.isLoading = false;
        state.botRights.isError = true;
        state.botRights.data = null;
      })
      // addRight
      .addCase(addRight.pending, (state) => {
        state.addRight.isLoading = true;
        state.addRight.message = '';
        state.addRight.isError = false;
        state.addRight.isSuccess = false;
        state.addRight.data = null;
      })
      .addCase(addRight.fulfilled, (state, action) => {
        state.addRight.isLoading = false;
        state.addRight.isSuccess = true;
        state.addRight.isError = false;
        state.addRight.message = '';
        state.addRight.data = action.payload;
      })
      .addCase(addRight.rejected, (state, action) => {
        state.addRight.message = action.payload;
        state.addRight.isLoading = false;
        state.addRight.isError = true;
        state.addRight.isSuccess = false;
        state.addRight.data = null;
      });
  },
});

export const { reset } = rightSlice.actions;
export default rightSlice.reducer;