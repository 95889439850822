import api from "../../../common/utils/api";

const listRuns = async (params) => {
    try {
        const response = await api().get("run/", {
            params,
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

const runsService = {
    listRuns
};

export default runsService;