import React, { useState } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import MoreVertModal from "../../modal/more-vert-modal";
import RobotModal from "../../modal/robotModal/robotModal";
import NoRecordFound from "../../no-record-found";
import { TableWrapper } from "../style";
import useRobotManagement from "./robotManagement.hook";
import AssignRobotModal from "../../modal/assignRobotModal/assignRobotModal";
import ViewUsersModal from "../../modal/viewUsersToRobotModal";
import DeleteAssignedUsersModal from "../../modal/deleteRobotUsersModal";

const RobotManagement = () => {
  const {
    addOpen,
    openMore,
    dataDisplay,
    search,
    setSearch,
    handleModal,
    handleMoreVertModal,
    currentPage,
    setCurrentPage,
    entriesPerPage,
    loading,
    assignModalOpen,
    handleAssignRobotModalOpen,
    handleAssignRobotModalClose,
    selectedBot,
    updateUserListForRobot,
    canManageRobots,
  } = useRobotManagement();

  const [viewUsersOpen, setViewUsersOpen] = useState(false);
  const [viewDeleteUsersOpen, setViewDeleteUsersOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [robotId, setRobotId] = useState(undefined);

  const handleViewUsers = (users) => {
    setSelectedUsers(users);
    setViewUsersOpen(true);
  };

  const handleDeleteUsers = (users, bot_id) => {
    setSelectedUsers(users);
    setRobotId(bot_id);
    setViewDeleteUsersOpen(true);
  }

  if (loading) {
    return (
      <div className="spinner-container">
        <BeatLoader className="beat-loader" color="#123abc" />
      </div>
    );
  }

  return (
    <>
      <div className="main">
        <div
          className="moreVert"
          style={{
            position: "absolute",
            right: "38px",
            top: "18.8rem",
            zIndex: 5,
          }}
        >
          {openMore && <MoreVertModal action={handleMoreVertModal} />}
        </div>
        {addOpen && <RobotModal show={true} action={handleModal} />}
        {assignModalOpen && (
          <AssignRobotModal
            show={true}
            action={handleAssignRobotModalClose}
            bot={selectedBot}
          />
        )}
      </div>

      <TableWrapper>
        <div className="containerDiv h-776">
          <div className="row h-776">
            <div className="mainDiv col-md-12">
              {/* Header */}
              <div className="topDiv">
                {/* Title */}
                <div className="rightPart">
                  <span>Robot Management</span>
                </div>

                {/* Search */}
                <div className="rightPart">
                  <div className="searchIcon">
                    <img src="/assets/images/sidebar/search.svg" alt="search" />
                  </div>
                  <input
                    autoComplete="off"
                    type="search"
                    className="form-control rounded searchField"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    style={{ minWidth: "344px" }}
                  />
                </div>
              </div>

              {/* Data */}
              <table id="example" className="table bottomDiv table-striped">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Version</th>
                    <th>Successful / Total Jobs</th>
                    <th>Users / Seats</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                {dataDisplay?.length > 0 ? (
                  <tbody>
                    {dataDisplay
                      .slice(
                        (currentPage - 1) * entriesPerPage,
                        currentPage * entriesPerPage
                      )
                      .map((robot, index) => (
                        <tr className="tableRows" key={index}>
                          <td>{robot.bot_name}</td>
                          <td>{robot.version}</td>
                          <td>
                            {robot.jobs[0]} / {robot.jobs[1]}
                          </td>
                          <td
                            className={`${robot.numberOfUsers > robot.seats
                              ? "font-red"
                              : ""
                              }`}
                          >
                            <div className="capacityWrapper">
                              {robot.numberOfUsers} / {robot.seats || "0"}
                              {robot.numberOfUsers > robot.seats && (
                                <img
                                  src="assets/images/table/warning.svg"
                                  alt="Capacity Exceeded"
                                  title="Robot seat capacity exceeded"
                                  style={{ marginLeft: "8px" }}
                                />
                              )}
                            </div>
                          </td>
                          <td>
                            <div className="status">
                              {!robot.is_deprecated ? (
                                <div className="color-succes">
                                  {" "}
                                  <span>Active</span>
                                </div>
                              ) : (
                                <div className="color-error">
                                  {" "}
                                  <span>Inactive</span>
                                </div>
                              )}
                            </div>
                          </td>
                          <td>
                            <div className="actions">
                              {canManageRobots && <img
                                src="assets/images/table/assignSeat.svg"
                                alt="Assign"
                                title="Assign seat"
                                className="tick-icon"
                                onClick={() =>
                                  handleAssignRobotModalOpen(robot)
                                }
                              />}
                              <img
                                src="assets/images/table/peopleGroup.svg"
                                alt="View assigned seats"
                                className="table-icon"
                                title="View assigned seats"
                                onClick={() =>
                                  handleViewUsers(robot.robotUsers)
                                }
                              />
                              {canManageRobots && <img
                                className="table-icon"
                                src="assets/images/table/groupRemove.svg"
                                alt="Free up a eat"
                                title="Free up a seat"
                                onClick={() =>
                                  handleDeleteUsers(
                                    robot.robotUsersAndRightIds,
                                    robot.bot_id
                                  )
                                }
                              />}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                ) : (
                  <NoRecordFound />
                )}
              </table>

              {/* Pagination */}
              {dataDisplay?.length > 0 && (
                <div className="pagination">
                  <button
                    className="previousNext"
                    onClick={() => setCurrentPage(1)}
                    disabled={currentPage === 1}
                  >
                    <img src="assets/images/table/previous.svg" alt="first" />
                    <span>First</span>
                  </button>
                  <button
                    className="previousNext"
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    <img
                      src="assets/images/table/previous.svg"
                      alt="previous"
                    />
                    <span>Previous</span>
                  </button>
                  <span>{currentPage}</span>
                  <button
                    className="previousNext"
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={
                      currentPage ===
                      (Number.isInteger(dataDisplay?.length / entriesPerPage)
                        ? Number(dataDisplay?.length / entriesPerPage)
                        : Math.ceil(dataDisplay?.length / entriesPerPage))
                    }
                  >
                    <span>Next</span>
                    <img src="assets/images/table/next.svg" alt="next" />
                  </button>
                  <button
                    className="previousNext"
                    onClick={() => {
                      setCurrentPage(
                        Number.isInteger(dataDisplay?.length / entriesPerPage)
                          ? Number(dataDisplay?.length / entriesPerPage)
                          : Math.ceil(dataDisplay?.length / entriesPerPage)
                      );
                    }}
                    disabled={
                      currentPage ===
                      (Number.isInteger(dataDisplay?.length / entriesPerPage)
                        ? Number(dataDisplay?.length / entriesPerPage)
                        : Math.ceil(dataDisplay?.length / entriesPerPage))
                    }
                  >
                    <span>Last</span>
                    <img src="assets/images/table/next.svg" alt="last" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </TableWrapper>

      <ViewUsersModal
        show={viewUsersOpen}
        closeModal={() => setViewUsersOpen(false)}
        users={selectedUsers}
      />
      <DeleteAssignedUsersModal
        show={viewDeleteUsersOpen}
        closeModal={() => setViewDeleteUsersOpen(false)}
        users={selectedUsers}
        robotId={robotId}
        updateUserListForRobot={updateUserListForRobot}
      />
    </>
  );
};

export default RobotManagement;
