import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createContact } from '../../provider/features/contact/contact.slice';
import { SupportWrapper } from './style';
import { getMe } from '../../provider/features/user-management/user-management.slice';
import ConfirmMessageModal from "../modal/supportModal/confirmation";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

const Support = () => {
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState('');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const SupportSchema = Yup.object().shape({
        subject: Yup.string().required('Required'),
        details: Yup.string().required('Required'),
      });

    const closeConfirmationModal = () => setShowConfirmationModal(false);

    const handleSubmit = async (values, { resetForm }) => {

        setErrorMessage('');

        const formData = {
            question_header: values?.subject,
            question_content: values?.details,
        };

        try {
            const resultAction = await dispatch(createContact({ formData }));
            if (createContact.fulfilled.match(resultAction)) {
                setShowConfirmationModal(true);
                resetForm();
            } else {
                setErrorMessage('Failed to submit your question. Please try again.');
            }
        
        } catch (error) {
            console.error('Error submitting contact form:', error);
            setErrorMessage('Failed to submit your question. Please try again.');
        }
    };

    return (
        <SupportWrapper>
            <ConfirmMessageModal
                show={showConfirmationModal}
                closeModal={closeConfirmationModal}
            />
            <div className="col-md-12 row">
                <div className="col-md-8 mb-10">
                    <p>Our team is happy to answer your questions. Fill out the form and we'll be in touch as soon as possible.</p>
                    <div className="question-form mt-10">
                        <Formik
                            initialValues={{
                                subject: '',
                                details: '',
                              }}
                              validationSchema={SupportSchema}
                              onSubmit={handleSubmit}
                        >
                            {({ errors, touched, isSubmitting }) => (
                            <Form>
                                <label className='mb-10'>Subject</label>
                                <Field
                                    type="text"
                                    placeholder="Summarize your question here"
                                    name="subject"
                                />
                                <div className="error-text mb-10">{errors.subject && touched.subject ? errors.subject : ""}</div>

                                <label className='mb-10'>Details</label>
                                <Field
                                    type="text"
                                    placeholder="Detail your question here"
                                    name="details"
                                    component="textarea"
                                ></Field>
                                {errors.details && touched.details ? (
                                <div className="error-text">{errors.details}</div>
                                ) : null}

                                <button className="submit-btn" type="submit" disabled={isSubmitting}>
                                    Submit
                                </button>
                                {errorMessage && <label className="error-text">{errorMessage}</label>}
                            </Form>
                            )}
                        </Formik>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="content-wrapper">
                        <div className="general-contact">
                            <div className="contact-info">
                                <div className="contact-details">
                                    <div className="logo">
                                        <img src="/assets/images/sidebar/logoImg.png" alt="Atomeus logo" />
                                    </div>
                                    <p style={{ color: '#0f5b72', fontWeight: '400' }}>ATOMEUS B.V.</p>
                                    <p>Savannahweg 17</p>
                                    <p>3542 AW Utrecht</p>
                                    <p>+31 (0)85 246 0392</p>
                                    <p><a href="mailto:support@atomeus.nl">support@atomeus.nl</a></p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </SupportWrapper>
    );
};

export default Support;
