import api from "../../../common/utils/api";

const listRobots = async () => {
    const response = await api().get("bot-management/", {
      withCredentials: true,
    });
    return response;
};

const getRobot = async(ids) => {
  const response = await api().get(`bot-management/?bot_id=${ids.join(",")}`, {
    withCredentials: true,
  });
  return response;
}

const robotManagementServices = {
  listRobots,
  getRobot,
};

export default robotManagementServices;