import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Setup2FAModalWrapper } from "../style4";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getQRImage, validate2FACode, saveQRToken } from "../../../provider/features/auth/auth.slice";
import { v4 as uuidv4 } from 'uuid';

const Setup2FAModal = ({ show, action }) => {
    const dispatch = useDispatch();
    const [step, setStep] = useState(1);
    const [showExtraInstructions, setShowExtraInstructions] = useState(false);
    const [codeDigits, setCodeDigits] = useState(["", "", "", "", "", ""]);
    const [qrImage, setQRImage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [failureMessage, setFailureMessage] = useState("");
    const navigate = useNavigate();

    const handleModal = (arg) => {
        action(arg);
    };

    const handleVerifyAndActivate = async () => {
        setFailureMessage("");
        const verificationCode = codeDigits.join("");
        const resultAction = await dispatch(validate2FACode({ code: verificationCode }));
        if (validate2FACode.fulfilled.match(resultAction)) {
            setSuccessMessage("Code was validated!");
            const response = await dispatch(saveQRToken());
            const guid = uuidv4();
            // Store the generated GUID in local storage
            localStorage.setItem('X-ATMS-Validation', guid);
            setTimeout(() => {
                window.location.href = '/';
            }, 2000);
        } else {
            setFailureMessage("Incorrect 2FA code!");
        }
    };

    const nextStep = () => {
        if (step < 3) {
            setStep(step + 1);
        } else {
            handleVerifyAndActivate();
        }
    }

    const previousStep = () => {
        if (step > 1) {
            setStep(step - 1);
        }
    }

    useEffect(() => {
        const userQRImage = async () => {
            try {
                const response = await dispatch(getQRImage());
                setQRImage(response.payload);
                console.log(response.payload);
            } catch (error) {
                console.error("2FA error:", error);
            }
        };
        userQRImage();
    }, [dispatch]);

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === "Escape") {
                handleModal(false);
            }
        };

        window.addEventListener("keydown", handleEsc);
        return () => {
            window.removeEventListener("keydown", handleEsc);
        };
    });

    const handleInputChange = (index, value) => {
        if (isNaN(value) || value.length > 1) return;
        const updatedDigits = [...codeDigits];
        updatedDigits[index] = value;
        setCodeDigits(updatedDigits);

        if (value && index < 5) {
            document.getElementById(`digit-${index + 1}`).focus();
        }
    };

    const handleBackspace = (index) => {
        if (codeDigits[index] === "" && index > 0) {
            document.getElementById(`digit-${index - 1}`).focus();
        }
    };

    return (
        <Modal show={show}>
            <Setup2FAModalWrapper>
                <div className="modal">
                    <div className="modalHeader">
                        <div className="modalTitle">Set Up Two-Factor Authentication</div>
                        <div className="crossIconDiv" onClick={() => handleModal(false)}>
                            <img
                                src="/assets/images/modal/cross.svg"
                                className="crossIcon"
                                alt="Close"
                            />
                        </div>
                    </div>

                    <div className="stepWrapper">
                        {step === 1 &&
                            <div className="step">
                                <span className="summary">Step 1: Install an authentication app</span>
                                <div className="stepContent">
                                    <div className="details">

                                        <div className="instructions">
                                            <span>Install Google Authenticator or Microsoft Authenticator on your mobile device.</span>
                                            <br /><br />
                                            <div className="logoWrapper">
                                                <div>
                                                    <img
                                                        height={60}
                                                        src="/assets/images/auth/google-authenticator50.svg"
                                                        className="logoImg"
                                                        alt=""
                                                    />
                                                    <span>Google Authenticator</span>
                                                </div>

                                                <div>
                                                    <img
                                                        style={{ padding: '5px' }}
                                                        src="/assets/images/auth/microsoft-authenticator.svg"
                                                        className="logoImg"
                                                        alt=""
                                                    />
                                                    <span>Microsoft Authenticator</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {step === 2 &&
                            <div className="step">
                                <span className="summary">Step 2: Scan the QR code using your authentication app</span>
                                <div className="stepContent">
                                    <div className="details">
                                        <div className="instructions">
                                            <span>- Open the authentication app</span><br />
                                            <span>- Tap the "+" icon in the top-right of the app</span><br />
                                            <span>- Scan the code to the right using your phone's camera</span>
                                            <br /><br />
                                        </div>
                                        <div>
                                            <img
                                                height={200}
                                                width={200}
                                                src={qrImage}
                                                className="logoImg"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {step === 3 &&
                            <div className="step">
                                <span className="summary">Step 3: Enter the verification code</span>
                                <div className="stepContent">
                                    <div className="details">
                                        <div className="instructions">
                                            <span>Lastly, you will find a six-digit verification code on your authentication app</span><br />
                                            <span>Enter the code on your screen to complete the setup</span><br />
                                        </div>

                                        <div className="verificationCodeInputContainer">
                                            {codeDigits.map((digit, index) => (
                                                <input
                                                    key={index}
                                                    id={`digit-${index}`}
                                                    type="text"
                                                    value={digit}
                                                    onChange={(e) => handleInputChange(index, e.target.value)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Backspace") handleBackspace(index);
                                                    }}
                                                    maxLength="1"
                                                    className="verificationDigitInput"
                                                />
                                            ))}

                                        </div>
                                        <div className="twoFAmessages">
                                            {successMessage && (
                                                <div className="success-text">{successMessage}</div>
                                            )}
                                            {failureMessage && (
                                                <div className="error-text">{failureMessage}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                    <div className="buttonWrapper">
                        {step !== 1 && (
                            <button className="back-btn" onClick={previousStep}>
                                Back
                            </button>
                        )}

                        <button
                            className="next-btn"
                            onClick={() => {
                                nextStep();
                            }}
                        >
                            {step === 3 ? "Verify and Activate" : "Next"}
                        </button>
                    </div>
                </div>
            </Setup2FAModalWrapper>
        </Modal>
    );
};

export default Setup2FAModal