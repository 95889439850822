import React, { useState, useEffect } from "react";
import { useNavigate  } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useRegister } from "./register.hook";
import Footer from "../../../common/landingpage/components/footer";
import InputFields from "../../../components/input-fields/input-fields";
import { AuthWrapper } from "../style";
import { RegisterSchema } from "../../../components/input-fields/input-fields";


const Register = () => {
  const {
    signedpk,
    isLoading,
    isError,
    isSuccess,
    message,
    handleSubmit,
  } = useRegister();
  
  const navigate = useNavigate();

  useEffect(() => {
    if (!signedpk) {
      navigate("/generic-error"); 
    }
  }, [signedpk, navigate]);

  useEffect(() => {
    if (isSuccess) {
      setAccountCreated(true);
    }
  }, [isSuccess]);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [accountCreated, setAccountCreated] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };


  return (
    <AuthWrapper>
      <div className="registration">
        <div className="registration-body">
          <div className="authForm">
            {!accountCreated ? (
              <>
                <div className="welcomHeadings">
                  <span className="first">WELCOME TO ATOMEUS</span>
                  <span className="second">Create your account</span>
                </div>

                <Formik
                  initialValues={{
                    password: "",
                    confirmPassword: "",
                  }}
                  validationSchema={RegisterSchema}
                  onSubmit={handleSubmit}
                >
                  {() => (
                    <Form className="formFields">
                      <div className="fields">
                        <label className="fieldLabel">Password</label>
                        <div className="password">
                          <InputFields
                            type={showPassword ? "text" : "password"}
                            name="password"
                            placeholder="Password"
                          />
                          <img
                            src="/assets/images/auth/eye.svg"
                            alt=""
                            className="eyeImg"
                            width="14.41px"
                            height="10.67px"
                            onClick={handleShowPassword}
                          />
                        </div>
                      </div>

                      <div className="fields">
                        <label className="fieldLabel">Confirm Password</label>
                        <div className="password">
                          <InputFields
                            type={showConfirmPassword ? "text" : "password"}
                            name="confirmPassword"
                            placeholder="Confirm Password"
                          />
                          <img
                            src="/assets/images/auth/eye.svg"
                            className="eyeImg"
                            width="14.41px"
                            height="10.67px"
                            alt=""
                            onClick={handleShowConfirmPassword}
                          />
                        </div>
                      </div>

                      <div className="bottomArea">
                        <button
                          type="submit"
                          className="submit-btn"
                          disabled={isLoading}
                          style={{ pointerEvents: isLoading ? "none" : "auto" }}
                        >
                          {isLoading ? "Creating Account..." : "Create Account"}
                        </button>
                      </div>
                      {isError && <div className="error">{message}</div>}
                    </Form>
                  )}
                </Formik>
              </>
            ) : (
              <div className="confirmation-wrapper">
              <div className="confirmation-message">Your account has been created successfully!</div>
                <button
                  className="go-to-login-btn"
                  onClick={() => navigate("/login")}
                >
                  Go to Login
                </button>
              </div>
            )}
          </div>
        </div>
        <footer>
          <Footer />
        </footer>
      </div>
    </AuthWrapper>
  );
};

export default Register;
