import { styled } from "styled-components";

export const Setup2FAModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: absolute;
  width: 800px;
  left: calc(50% - 800px / 2);
  margin: auto;
  background: #212036;
  top: 180px;
  border-radius: 8px;

  .modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding-bottom: 24px;

    position: absolute;
    width: 100%;
    height: fit-content;
    background: #2c2b41;
    border-radius: 10px;

    .modalHeader {
        width: 100%;
        background: #212036;
        padding: 10px 15px 28px 26px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .modalTitle {
            padding-top: 20px;
            width: 100%;
            text-align: center;
            text-weight: 450;
            font-size : 22px;
        }

        .crossIconDiv {
            width: 24px;
            height: 24px;
            padding: 8px;
            background: #2c2b41;
            border-radius: 50%;
            cursor: pointer;
        .crossIcon {
            width: 8px;
            height: 8px;
            display: flex;
            align-items: center;
        }
      }
        
    }

    .stepWrapper{
        height: 350px;
        padding: 0px 20px;
        min-width: 710px;

        .step{
            display: flex;
            flex-direction : column;
            justify-content: space-between;
            .stepNumber {
                font-weight: 400;
                font-size: 16px;
                line-height: 140%;
                color: #99a2ac;
            }
             .summary {
                        font-weight: 500;
                        font-size: 20px;!important
                        line-height: 140%;
            }   
            .stepContent {
                height: 100%;
                display: flex;
                flex-direction : row;

                .details {
                    width: 100%;
                    display: flex;
                    flex-direction : column;
                    padding-top: 4px;   

                    .instructions {
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 140%;
                        color: #99a2ac;
                        padding-top: 6px;
                    }

                    .logoWrapper {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        margin-top: 20px;
                        gap: 150px;
                    }

                    .manualEntryKeys {
                        color: red;
                        font-size: 20px;
                        padding-top: 30px;
                    }
                     
                    .twoFAmessages {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        padding-top: 40px;
                        
                        .error-text {
                            font-size: 16px;
                            font-weight: 600;
                        }
                        .success-text {
                            font-size: 16px;
                            color: #048100;
                            font-weight: 600;
                        }
                    }   

                    .verificationCodeInputContainer {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        margin-top: 100px;
                        gap: 20px;

                        .verificationDigitInput {
                            width: 50px;
                            height: 50px;
                            text-align: center;
                            background: #2c2b41;
                            border-radius: 6px;
                            border: 1px solid #2c2b41;
                            font-weight: 400;
                            font-size: 20px;
                            line-height: 24px;
                            color: #ffffff;
                            background: #212036;
                            outline: none !important;
                            background-image: none;
                        }
                    }
                }
            }
        }
    }

    .buttonWrapper {

        display: flex;
        flex-direction: row;
        gap: 40px;
        justify-content: space-between;
        align-items:center;

        .back-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #212036;
            border: 1px solid #338bc2;
            box-shadow: 0px 0px 10px rgba(75, 255, 247, 0.5);
            border-radius: 50px;
            padding: 16px 34.5px; 
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            color: #ffffff;
            height: 40px; 
            width: fit-content;
        }

        .next-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #086a8f;
            border: 1px solid #338bc2;
            box-shadow: 0px 0px 10px rgba(75, 255, 247, 0.5);
            border-radius: 50px;
            padding: 16px 34.5px; 
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            color: #ffffff;
            height: 40px; 
            width: fit-content;
        }
    }
}
`;