import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { listRobots } from "../../../provider/features/robot-management/robot-management.slice";
import versionService from "../../../provider/features/version/version.service";
import runsService from "../../../provider/features/run/run.service";
import rightServices from "../../../provider/features/right/right.service";

const useRobotManagement = () => {
  const [loading, setLoading] = useState(true);
  const [addOpen, setAddOpen] = useState(false);
  const [openMore, setOpenMore] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [data, setData] = useState([]);
  const [dataDisplay, setDataDisplay] = useState([]);
  const [search, setSearch] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedBot, setSelectedBot] = useState(null);
  const [assignModalOpen, setAssignModalOpen] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const me = useSelector((state) => state.userManagement.me);
  const [canManageRobots, setCanManageRobots] = useState(false);

  const fetchUsers = async (bot_code) => {
    try {
      const response = await rightServices.listRights({
        search: bot_code,
      });

      const numberOfUsers = response.length || 0;
      return numberOfUsers;
    } catch (error) {
      console.error("Failed to fetch users data for bot_code:", bot_code);
    }
  }

  const fetchUsersList = async (bot_code) => {
    try {
      const response = await rightServices.listRights({
        search: bot_code,
      });

      let users = response.map(user => {
        const namePart = user.right_code.split("for ")[1];

        const nameParts = namePart.split("-");

        const lastName = nameParts.pop();
        const firstName = nameParts.slice(1).join(" ");

        return { firstName, lastName };
      });

      return users;
    } catch (error) {
      console.error("Failed to fetch users data for bot_code:", bot_code);
    }
  }

  const fetchUsersAndRights = async (bot_code) => {
    try {
      const response = await rightServices.listRights({
        search: bot_code,
      });

      let users = response.map(user => {
        const rightId = user.right_id;
        const namePart = user.right_code.split("for ")[1];

        const nameParts = namePart.split("-");

        const lastName = nameParts.pop();
        const firstName = nameParts.slice(1).join(" ");

        return { rightId, firstName, lastName };
      });

      return users;
    } catch (error) {
      console.error("Failed to fetch users data for bot_code:", bot_code);
    }
  }

  const fetchJobs = async (bot_code) => {
    try {
      const response = await runsService.listRuns({
        search: bot_code,
      });

      const numberOfJobs = response.length || 0;
      let numberOfSuccessfulJobs = 0;

      response.forEach(job => {
        if (job.status === "successful") {
          numberOfSuccessfulJobs++;
        }
      });

      return [numberOfSuccessfulJobs, numberOfJobs];
    } catch (error) {
      console.error("Failed to fetch job data for bot_code: ", bot_code, error);
      return [0, 0];
    }
  };

  const fetchVersion = async (bot_code) => {
    try {
      const response = await versionService.listVersions({
        ordering: "version_name",
        search: bot_code,
      });

      const versionName = response[0].version_name || "N/A";

      return versionName;
    } catch (error) {
      console.error("Failed to fetch version for bot_code:", bot_code, error);
      return "N/A";
    }
  };

  const getRobotsData = async () => {
    const response = await dispatch(listRobots());
    const robots = response?.payload?.data || [];

    const updatedRobots = await Promise.all(
      robots.map(async (robot) => {
        const version = await fetchVersion(robot.bot_code);
        const jobs = await fetchJobs(robot.bot_code);
        const numberOfUsers = await fetchUsers(robot.bot_code);
        const robotUsers = await fetchUsersList(robot.bot_code);
        const robotUsersAndRightIds = await fetchUsersAndRights(robot.bot_code);
        return { ...robot, version, jobs, numberOfUsers, robotUsers, robotUsersAndRightIds };
      })
    );
    setLoading(false);
    setData(updatedRobots);
  };

  useEffect(() => {
    setLoading(true);
    getRobotsData();
  }, [dispatch]);

  useEffect(() => {
    setDataDisplay(
      data ?
        data.filter(x => fullTextSearch(x, search)) :
        []
    );
  }, [data, search, currentPage, entriesPerPage]);

  useEffect(() => {
    setSearch("");
  }, [location.key]);

  useEffect(() => {
    setCanManageRobots(me.data?.is_key_user);
  }, [me.data]);

  const fullTextSearch = (obj, searchTerm) => {
    if (!searchTerm || searchTerm === "") {
      return true;
    }

    const searchableString = Object.values(obj)
      .join(" ")
      .toLowerCase();                 // Convert to lowercase for case-insensitive search

    return searchableString.includes(searchTerm.toLowerCase());
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const openEL = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModal = (arg) => {
    setAddOpen(arg);
  };

  const handleMoreVertModal = (previous) => {
    setOpenMore(!previous);
  };

  const handleAssignRobotModalOpen = (bot) => {
    setAssignModalOpen(true);
    setSelectedBot(bot);
  }

  const handleAssignRobotModalClose = () => {
    setAssignModalOpen(false);
    setSelectedBot(null);
    getRobotsData();
  }

  const updateUserListForRobot = async (robotId, rightId) => {
    const newData = JSON.parse(JSON.stringify(data));
    const robotIndex = newData.findIndex(robot => robot.bot_id === robotId);
    const robot = newData[robotIndex];
    const newUsers = robot.robotUsersAndRightIds.filter(user => user.rightId !== rightId);
    robot.robotUsersAndRightIds = newUsers;
    newData[robotIndex] = robot;
    newData[robotIndex].numberOfUsers--;
    setData(newData);
  }

  return {
    addOpen,
    openMore,
    anchorEl,
    dataDisplay,
    search,
    setSearch,
    openEL,
    selectedBot,
    handleClick,
    handleClose,
    handleModal,
    handleMoreVertModal,
    assignModalOpen,
    handleAssignRobotModalOpen,
    handleAssignRobotModalClose,
    currentPage,
    setCurrentPage,
    entriesPerPage,
    loading,
    updateUserListForRobot,
    canManageRobots,
  };
};

export default useRobotManagement;
