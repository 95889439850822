import api from "../../../common/utils/api";

const getCard = async () => {
  const response = await api().get("/v0/policy/list");
  return response.data;
};

const landingpageService = {
  getCard,
};

export default landingpageService;
