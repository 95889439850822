import api from "../../../common/utils/api"; 

const createContact = async (data) => {
    const response = await api().post("contact/", data);
    return response;
};

const contactServices = {
    createContact,
};

export default contactServices;
