import { Form, Formik } from "formik";
import React, { useState } from "react";
import InputFields from "../../../components/input-fields/input-fields";
import { AuthWrapper } from "../style";
import useForgotPassword from "./use-forgot-password.hook";
import { useNavigate, Link } from 'react-router-dom';
import InlineLoader from "../../../common/components/inline-loader/inline-loader.component";

const ForgotPassword = () => {
  const { ForgotPasswordSchema, handleForgotPassword } = useForgotPassword();
  const [submitted, setSubmitted] = useState(false);
  const [message, setMessage ] = useState("");
  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    navigate('/login');
  };

  return (
      <AuthWrapper>
        <div className="login">
          <div className="authForm">
            <div className="brand">
              <img
                src="/assets/images/auth/logo.svg"
                className="logoImg"
                alt=""
              />
              <span className="companyName">ATOMEUS</span>
            </div>

            <div className="loginHeadings formFields" hidden={!submitted}>
              <span className="secondHeadingAlt">
                {message}
              </span>
              <div className="bottomArea">
                <button
                  type="button"
                  className="submit-btn"
                  onClick={handleBackButtonClick}
                >
                  Back to Login page
                </button>
              </div>
            </div>

            <div className="loginHeadings" hidden={submitted}>
              <span className="firstHeading">Forgot Password?</span>
              <span className="secondHeading">
                Please enter your email address to reset your password.
              </span>
            </div>

            <div hidden={submitted}>
              <Formik
                initialValues={{ email: "" }}
                validationSchema={ForgotPasswordSchema}
                onSubmit={(values, actions) => handleForgotPassword(values, actions, setSubmitted, setMessage)}
              >
                {({ isSubmitting }) => (
                  <Form className="formFields">
                    <div className="fields">
                      <label className="fieldLabel">Email</label>
                      <InputFields
                        type="email"
                        name="email"
                        placeholder="Email"
                        autoFill="false"
                      />
                    </div>

                    <div className="bottomArea">
                      <button
                        type="submit"
                        className="submit-btn"
                        disabled={isSubmitting}
                      >
                        {!isSubmitting && "Send Link to Reset Password"}
                        {isSubmitting && <InlineLoader />}
                      </button>
                      <div>
                        <Link to="/login" className="forgotPassword">
                          Back to Login
                        </Link>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </AuthWrapper>
  );
};

export default ForgotPassword;
