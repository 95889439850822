  import { Field, Form, Formik } from "formik";
  import React, { useEffect, useState } from "react";
  import { Modal } from "react-bootstrap";
  import * as Yup from "yup";
  import { ModalWrapper } from "../style";
  import { UserRole } from '../../../enums/user-roles';
  import { useDispatch } from "react-redux";
  import { registerKey } from "../../../provider/features/user-management/user-management.slice";

  const SignupSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(30, "Too Long!")
      .required("Required"),
    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(30, "Too Long!")
      .required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    role: Yup.string()
      .oneOf([UserRole.KEY_USER, UserRole.USER], "Required")
      .required("Required"),
  });

  const UserModal = ({ show, action, getUsersData }) => {
    const dispatch = useDispatch();

    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const handleModal = (arg) => {
      action(arg);
    };

    useEffect(() => {
      const handleEsc = (event) => {
        if (event.key === "Escape") {
          handleModal(false);
        }
      };

      window.addEventListener("keydown", handleEsc);
      return () => {
        window.removeEventListener("keydown", handleEsc);
      };
    });

    const handleSubmit = async (values, { resetForm, setStatus, setFieldError  }) => {
      const payload = {
        first_name: values.name,
        last_name: values.lastName,
        email: values.email,
        is_key_user: values.role === UserRole.KEY_USER,
      };
      const resultAction = await dispatch(registerKey(payload));
  
      if (registerKey.fulfilled.match(resultAction)) {
        setSuccessMessage("The invitation has been sent successfully!");
        setTimeout(() => {
          handleModal(false);
          getUsersData();
        }, 1500);
        resetForm();
      } else if (registerKey.rejected.match(resultAction)) {
        setFieldError("email", "Email is already in use.");
      }
    };

    function getEmailCssClass(touched, errors) {
      return touched.email && !errors.email
        ? "valid"
        : touched.email && errors.email
          ? "error"
          : "";
    };

    function getLastNameCssClass(touched, errors) {
      return touched.lastName && !errors.lastName
        ? "valid"
        : touched.lastName && errors.lastName
          ? "error"
          : "";
    };

    function getFirstNameCssClass(touched, errors) {
      return touched.name && !errors.name
        ? "valid"
        : touched.name && errors.name
          ? "error"
          : "";
    };

    return (
      <Modal show={show}>
        <ModalWrapper>
          <div className="modal">
            <div className="topPart">
              <div className="user">
                <div className="userIconDiv">
                  <img
                    src="/assets/images/modal/addUser.svg"
                    className="userIcon"
                    alt=""
                  />
                </div>
                <div className="details">
                  <span className="first">Add New User</span>
                  <span className="second">Add user details</span>
                </div>
              </div>
              <div className="crossIconDiv" onClick={() => handleModal(false)}>
                <img
                  src="/assets/images/modal/cross.svg"
                  className="crossIcon"
                  alt=""
                />
              </div>
            </div>
            {/* Form */}
            <Formik
              initialValues={{
                name: "",
                lastName: "",
                email: "",
                role: "",
              }}
              validationSchema={SignupSchema}
              validateOnSubmit={true}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, values }) => (
                <Form className="form_wrapper">
                  {/* First Name */}
                  <div className="field mb-2">
                    <label className="fieldLabels">First Name</label>
                    <Field
                      name="name"
                      type="text"
                      className={`text-field form-control ${getFirstNameCssClass(
                        touched,
                        errors
                      )}`}
                      placeholder="First Name"
                    />
                    {errors.name && touched.name ? (
                      <div className="error-text">{errors.name}</div>
                    ) : null}
                  </div>
                  {/* Last Name */}
                  <div className="field mb-2">
                    <span className="fieldLabels">Last Name</span>

                    <Field
                      name="lastName"
                      type="text"
                      className={`text-field form-control ${getLastNameCssClass(
                        touched,
                        errors
                      )}`}
                      placeholder="Last Name"
                    />
                    {errors.lastName && touched.lastName ? (
                      <div className="error-text">{errors.lastName}</div>
                    ) : null}
                  </div>
                  {/* Email */}
                  <div className="field mb-2">
                    <span className="fieldLabels">Email</span>

                    <div className="email-container">
                      <Field
                        name="email"
                        type="email"
                        className={`email-field form-control ${getEmailCssClass(
                          touched,
                          errors
                        )}`}
                        placeholder="User's email"
                      />
                      <div className="emailDiv">
                        <img
                          src="/assets/images/modal/mail.svg"
                          className="emailIcon"
                          alt=""
                        />
                      </div>
                    </div>

                    {errors.email && touched.email ? (
                      <div className="error-text">{errors.email}</div>
                    ) : null}
                  </div>
                  {/* Role */}
                  <div className="field mb-2">
                    <span className="fieldLabels">Role</span>

                    <div className="role-container">
                      <Field
                        as="select"
                        name="role"
                        className={`form-select form-select-sm text-field form-control ${
                          !values.role ? "text-muted" : ""
                        }`}
                        onClick={() => setDropdownOpen((prev) => !prev)}
                        onBlur={() => setDropdownOpen(false)}
                      >
                        <option value="">Select role</option>
                        <option value={UserRole.KEY_USER}>Key User</option>
                        <option value={UserRole.USER}>User</option>
                      </Field>

                      <div className="downArrowDiv">
                        <img
                          src="/assets/images/dashboard/arrow-down.svg"
                          className={`downArrowIcon ${
                            isDropdownOpen ? "rotate" : ""
                          }`}
                          alt="Arrow Icon"
                        />
                      </div>
                    </div>
                    {errors.role && touched.role ? (
                      <div className="error-text">{errors.role}</div>
                    ) : null}
                  </div>

                  {successMessage && (
                    <div className="success-text">{successMessage}</div>
                  )}

                  {/* Actions */}
                  <div className="bottom">
                    <button
                      className="cencel-btn"
                      onClick={() => handleModal(false)}
                    >
                      Cancel
                    </button>

                    <button type="submit" className="submit-btn">
                      Send Invite
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </ModalWrapper>
      </Modal>
    );
  };

  export default UserModal;
