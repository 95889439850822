import { styled } from "styled-components";

export const ConfirmModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: absolute;
  width: 490px;
  left: calc(50% - 480px / 2);
  bottom: calc(10% - 450px / 2);
  background: #212036;
  border-radius: 8px;
  list-style: decimal !important;

  .modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 100%;
    max-width: 490px;
    height: fit-content;
    background: #2c2b41;
    border-radius: 10px;

    .topPart {
      width: 100%;
      background: #212036;
      padding: 32px;
      display: flex;
      justify-content: space-between;

      .user {
        display: flex;
        gap: 16px;
        .userIconDiv {
          .userIcon {
            width: 50px;
            height: 50px;
            padding: 12px;
            background: #2c2b41;
            border-radius: 11.25px;
          }
        }

        .details {
          display: flex;
          flex-direction: row;
          gap: 4px;
          .first {
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            color: #f4f6f8;
            align-self: center;
          }
        }
      }
      .crossIconDiv {
        width: 24px;
        height: 24px;
        padding: 8px;
        background: #2c2b41;
        border-radius: 50%;
        cursor: pointer;
        .crossIcon {
          width: 8px;
          height: 8px;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .bottomPart {
    width: 100%;

    .content {
      margin: 10px 10px 10px 10px;
      padding: 20px 20px;
      background: #212036;
      border-radius: 4px;
      border: 1px solid #2c2b41;

      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 470px;
      width: 100%;

      .confirmationMessage {
        margin-bottom: 5px;
        margin-top: 10px;
        white-space: pre-line;
      }

      .error-text {
        padding-top: 10px; 
      }

      .buttonWrapper {
        display: flex;
        flex-direction: row;
        gap: 24px;
        justify-content: space-evenly;
        padding-top: 20px;

        .delete-btn {
          align-items: center;
          display: flex;
          background: #086a8f;
          border: 1px solid #338bc2;
          box-shadow: 0px 0px 10px rgba(75, 255, 247, 0.5);
          border-radius: 50px;
          padding: 12px 34.5px;
          font-weight: 400;
          font-size: 16px;
          line-height: 26px;
          color: #ffffff;
          height: 32px;
          width: fit-content;
        }
        .cencel-btn {
          align-items: center;
          display: flex;
          background: #212036;
          border: 1px solid #338bc2;
          box-shadow: 0px 0px 10px rgba(75, 255, 247, 0.5);
          border-radius: 50px;
          padding: 12px 34.5px;
          font-weight: 400;
          font-size: 16px;
          line-height: 26px;
          color: #ffffff;
          height: 32px;
          width: fit-content;
        }
      }
    }
  }
`;
