import React, { useEffect, useState, u } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from "react-bootstrap";
import { ConfirmModalWrapper } from "./style";
import { createContact } from '../../../provider/features/contact/contact.slice';
import ConfirmMessageModal from "../supportModal/confirmation";
import { useCreateContact } from "./request.hook";



const SeatRequestModal = ({ show, title, message, onCancel, data, closeParentModal}) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const me = useSelector((state) => state.userManagement.me);


  const {
    isLoading,
    isSuccess,
    isError,
    submitMessage,
    handleSubmit,
  } = useCreateContact();

  const closeConfirmationModal = () => {
    onCancel();
    setShowConfirmationModal(false);
  }

  const closeAll = () => {
    closeConfirmationModal()
    closeParentModal()
  }

  useEffect(() => {
    if (isSuccess) {
			setShowConfirmationModal(true);
		}
  }, [isSuccess]);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        onCancel();
      }
    };
    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [onCancel]);

  const initialValues = {
    question_header: `Additional seat requested by ${me.data?.user_code} from ${me.data?.user_code.split("-")[0]}`,
    question_content: 
      `Additional seat requested by ${me.data?.user_code} from ${me.data?.user_code.split("-")[0]}
      Bot code: ${data.botCode}
      Current maximum seats: ${data.botSeatLimit}
      Bot seat cost: ${data.botSeatCost}
      Timestamp: ${new Date().toISOString()}`,
  };

  const onFormSubmit = () => {
    handleSubmit(initialValues, 
      setErrorMessage,
      () => {
				setTimeout(() => {
					closeConfirmationModal();
				}, 1500);
			}
    );
  };

  return (
		<>
			<ConfirmMessageModal
				show={showConfirmationModal}
				closeModal={closeAll}
			/>
			<Modal show={show && !showConfirmationModal} onHide={onCancel}>
				<ConfirmModalWrapper show={!showConfirmationModal}>
					<Formik initialValues={initialValues} onSubmit={onFormSubmit}>
						<Form>
							<div className="modal">
								<div className="topPart">
									<div className="user">
										<div className="userIconDiv">
											<img
												src="/assets/images/table/assignSeat.svg"
												className="userIcon"
												alt="Confirm"
											/>
										</div>
										<div className="details">
											<div className="first">{title}</div>
										</div>
									</div>
									<div className="crossIconDiv" onClick={onCancel}>
										<img
											src="/assets/images/modal/cross.svg"
											className="crossIcon"
											alt="Close"
										/>
									</div>
								</div>
								<div className="bottomPart">
									<div className="content">
										<div className="confirmationMessage">{message}</div>
										<div className="buttonWrapper">
											<button
												type="button"
												className="cencel-btn"
												onClick={onCancel}
											>
												Cancel
											</button>
											<button
												type="submit"
												className=" delete-btn"
												style={{ pointerEvents: isLoading ? "none" : "auto" }}
											>
												{isLoading ? "Sending request..." : "Request an additional seat"}{" "}
											</button>
										</div>
                    <label className="error-text">{errorMessage}</label>
									</div>
								</div>
							</div>
						</Form>
					</Formik>
				</ConfirmModalWrapper>
			</Modal>
		</>
	);
};

export default SeatRequestModal;
