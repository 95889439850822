import { useState, useRef } from "react";
import { useLocation } from 'react-router-dom';
import Card from "../../components/dashboard/card/card";
import Chart from "../../components/dashboard/charts/chart";
import Header from "../../components/dashboard/header/header";
import { DashboardWrapper } from "./style";

function Dashboard() {
  const [layout, setLayout] = useState("");
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const user_id = params.get('user_id');
  const bot_id = params.get('bot_id');
  const targetRef = useRef(null);

  const handburger = (arg) => {
    setLayout(arg);
  };

  return (
    <div className={layout ? "mainWrapper collapsed-layout" : "mainWrapper"}>
      <DashboardWrapper>
        <div>
        </div>
        <div>
          <Header user_id={user_id} />
          <Card  user_id={user_id} bot_id={bot_id} targetRef={targetRef} />
          <Chart user_id={user_id} bot_id={bot_id} targetRef={targetRef} />
        </div>
      </DashboardWrapper>
    </div>
  );
}

export default Dashboard;
