import React, { useState } from "react";
import { useNavigate  } from "react-router-dom";
import { Formik, Form } from "formik";
import { useChangePassword } from "./change-password.hook";
import InputFields from "../../../components/input-fields/input-fields";
import { AuthWrapper } from "../style";
import { RegisterSchema } from "../../../components/input-fields/input-fields";


const ChangePasswordPage = () => {

      const navigate = useNavigate();
    
      const [showPassword, setShowPassword] = useState(false);
      const [showConfirmPassword, setShowConfirmPassword] = useState(false);
      const [passwordChanged, setPasswordChanged] = useState(false);

      const {
        handleSubmit,
        isLoading,
        isError,
        isSuccess,
        message,
      } = useChangePassword();  
    
      const handleShowPassword = () => {
        setShowPassword(!showPassword);
      };
    
      const handleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
      };

      if (isSuccess && !passwordChanged) {
        setPasswordChanged(true);
      }
    
      return (
				<AuthWrapper>
					<div className="change-password">
						<div className="change-password-body">
							<div className="authForm">
								{!passwordChanged ? (
									<>
										<div className="welcomHeadings">
											<span className="second">
												It has been more than{" "}
												{process.env.REACT_APP_CHANGE_PASSWORD_INTERVAL} days
												since you last changed your password. For your security,
												please update it now.
											</span>
										</div>

										<Formik
											initialValues={{
												password: "",
												confirmPassword: "",
											}}
											validationSchema={RegisterSchema}
											onSubmit={handleSubmit}
										>
											{() => (
												<Form className="formFields">
													<div className="fields">
														<label className="fieldLabel">Password</label>
														<div className="password">
															<InputFields
																type={showPassword ? "text" : "password"}
																name="password"
																placeholder="Password"
															/>
															<img
																src={`/assets/images/auth/${
																	!showPassword ? "eye.svg" : "eye_slash.svg"
																}`}
																className="eyeImg"
																width={`${
																	!showPassword ? "14.41px" : "17.41px"
																}`}
																height={`${
																	!showPassword ? "10.67px" : "13.67px"
																}`}
																alt=""
																onClick={handleShowPassword}
															/>
														</div>
													</div>

													<div className="fields">
														<label className="fieldLabel">
															Confirm Password
														</label>
														<div className="password">
															<InputFields
																type={showConfirmPassword ? "text" : "password"}
																name="confirmPassword"
																placeholder="Confirm Password"
															/>
															<img
																src={`/assets/images/auth/${
																	!showConfirmPassword ? "eye.svg" : "eye_slash.svg"
																}`}
																className="eyeImg"
																width={`${
																	!showConfirmPassword ? "14.41px" : "17.41px"
																}`}
																height={`${
																	!showConfirmPassword ? "10.67px" : "13.67px"
																}`}
																alt=""
																onClick={handleShowConfirmPassword}
															/>
														</div>
													</div>

													<div className="bottomArea">
														<button
															type="submit"
															className="submit-btn"
															disabled={isLoading}
															style={{
																pointerEvents: isLoading ? "none" : "auto",
															}}
														>
															{isLoading
																? "Changing Password..."
																: "Change Password"}
														</button>
													</div>
													{isError && <div className="error">{message}</div>}
												</Form>
											)}
										</Formik>
									</>
								) : (
									<div className="confirmation-wrapper">
										<div className="confirmation-message">
											Your password has been changed successfully!
										</div>
										<button
											className="go-to-login-btn"
											onClick={() => navigate("/login")}
										>
											Go to Login
										</button>
									</div>
								)}
							</div>
						</div>
					</div>
				</AuthWrapper>
			);
}

export default ChangePasswordPage;
