import styled from "styled-components";

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-image: url("../../../public/assets/images/auth/background.jpg");
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 

  h1 {
    font-size: 80px; 
  }
`;

export const ErrorMessage = styled.p`
  font-size: 1.5rem;
  color: white;
  margin: 1rem 0;
`;

export const ErrorButton = styled.a`
  background: #086a8f;
  border: 1px solid #338bc2;
  box-shadow: 0px 0px 10px rgba(75, 255, 247, 0.5);
  border-radius: 50px;
  padding: 12px 34.5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;
  text-decoration: none; 
  display: inline-block; 
  cursor: pointer;

  &:hover {
    background: #0a739d; 
    color: white;
  }
`;
