import React from "react";
import Support from "../../components/support";
import { SupportWrapper } from "./style";
import { useState } from "react";
import Sidebar from "../../common/sidebar/sidebar";
import { useLocation } from 'react-router-dom';


function SupportPage() {
    const [layout, setLayout] = useState("");
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const handburger = (arg) => {
        setLayout(arg);
    };


    return (
        <div className={layout ? "mainWrapper collapsed-layout" : "mainWrapper"}>

            <SupportWrapper>
                <div>
                </div>
                <div>
                    <Support />
                </div>
            </SupportWrapper>

        </div>
    );
}

export default SupportPage;