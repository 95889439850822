import React from "react";
import BeatLoader from "react-spinners/BeatLoader";
import UserModal from "../../modal/userModal/user";
import NoRecordFound from "../../no-record-found";
import { TableWrapper } from "../style";
import useUserManagement from "./userManagement.hook";
import EditUserModal from "../../modal/userModal/editUser";
import DeleteUserModal from "../../modal/userModal/deleteUser";
import RobotListModal from "../../modal/userModal/robotsList";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

const UserManagement = () => {
  const {
    addOpen,
    editOpen,
    deleteOpen,
    selectedUser,
    dataDisplay,
    search,
    selectedStatus,
    setSelectedStatus,
    setSearch,
    handleModal,
    handleEditModal,
    closeEditModal,
    handleDeleteModal,
    closeDeleteModal,
    handleRobotsModal,
    robotsListOpen,
    closeRobotsModal,
    currentPage,
    setCurrentPage,
    entriesPerPage,
    loading,
    getUsersData,
  } = useUserManagement();

  const navigate = useNavigate();

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
    setSearch("");
    setCurrentPage(1); 
  };

  if (loading) {
    return (
      <div className="spinner-container">
        <BeatLoader className="beat-loader" color="#123abc" />
      </div>
    );
  }

  return (
    <>
      {/* Add modal */}
      <div>
        {addOpen &&
          <UserModal
            show={true}
            action={handleModal}
            getUsersData={getUsersData} />
        }
      </div>

      {/* Edit modal */}
      <div>
        {editOpen && (
          <EditUserModal
            show={true}
            action={closeEditModal}
            user={selectedUser}
            getUsersData={getUsersData}
          />
        )}
      </div>

      {/* Delete modal */}
      <div>
        {deleteOpen && (
          <DeleteUserModal
            show={true}
            closeModal={closeDeleteModal}
            user={selectedUser}
            getUsersData={getUsersData}
          />
        )}
      </div>

      {/* Robots modal */}
      <div>
        {robotsListOpen && (
          <RobotListModal
            show={true}
            user={selectedUser}
            action={closeRobotsModal}
          ></RobotListModal>
        )}
      </div>

      {/* Data */}
      <TableWrapper>
        <div className="containerDiv h-776">
          <div className="row h-776">
            <div className="mainDiv col-md-12">
              <div className="topDiv">
                <div className="rightPart">
                  <span>User Management</span>
                </div>
                <div className="rightPart">
                  <div className="searchIcon">
                    <img src="/assets/images/sidebar/search.svg" alt="search" />
                  </div>
                  <input
                    autoComplete="off"
                    type="search"
                    className="form-control rounded searchField"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => { setSearch(e.target.value); setCurrentPage(1); }}
                    style={{ minWidth: "344px" }}
                  />
                                    <Dropdown
                    className="statusDropdown"
                    style={{ marginLeft: "10px" }}
                    onSelect={handleStatusChange} 
                  >
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                      User Status
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        eventKey="all"
                        active={selectedStatus === 'all'} 
                        onClick={() => handleStatusChange('all')}
                      >
                        All
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="active"
                        active={selectedStatus === 'active'} 
                        onClick={() => handleStatusChange('active')}
                      >
                        Active
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="inactive"
                        active={selectedStatus === 'inactive'} 
                        onClick={() => handleStatusChange('inactive')}
                      >
                        Inactive
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <button className="add-btn" onClick={() => handleModal(true)}>
                    <img src="/assets/images/table/plusIcon.svg" alt="add" />
                    <span>Add</span>
                  </button>
                </div>
              </div>

              {/* Table */}
              <table
                id="example"
                className="table bottomDiv table-striped"
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Bots Assigned</th>
                    <th>Role</th>
                    <th>Last Login</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                {dataDisplay?.length > 0 ? (
                  <tbody>
                    {dataDisplay?.slice((currentPage - 1) * entriesPerPage, currentPage * entriesPerPage).map((user, index) => {
                      return (
                        <tr className="tableRows" key={index}>
                          {/* Name */}
                          <td>{user.first_name} {user.last_name}</td>

                          {/* Robots */}
                          <td>
                            <span
                              className="cursor-pointer-robots"
                              onClick={() => handleRobotsModal(user)}
                            >{user.rights?.length}</span>
                          </td>

                          {/* Role */}
                          <td>{user.is_key_user ? "Key User" : "User"}</td>

                          {/* Last Login */}
                          <td>{user.last_login ? DateTime.fromISO(user.last_login).toFormat('yyyy-MM-dd') : "N/A"}</td>

                          {/* Status */}
                          <td>
                            <div className="status">
                              {user.is_active ? <div className="color-succes"> <span>Active</span></div> : <div className="color-error"> <span>Inactive</span></div>}
                            </div>
                          </td>

                          {/* Actions */}
                          <td>
                            <div className="actions">

                              {/* Dashboard */}
                              <img
                                src="/assets/images/table/stackedbar.svg"
                                alt="dashboard"
                                title="View user dashboard"
                                className="dashboard-icon"
                                onClick={() => navigate(`/dashboard?user_id=${user.user_id}`)}
                              />

                              {/* Edit */}
                              <img
                                src="/assets/images/table/editIcon.svg"
                                className="edit-icon"
                                alt="edit"
                                title="Edit user"
                                onClick={() => handleEditModal(user)}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <NoRecordFound />
                )}
              </table>

              {/* Pagination */}
              {dataDisplay?.length > 0 && (
                <div className="pagination">
                  <button
                    className="previousNext"
                    onClick={() => setCurrentPage(1)}
                    disabled={currentPage === 1}
                  >
                    <img src="assets/images/table/previous.svg" alt="first" />
                    <span>First</span>
                  </button>
                  <button
                    className="previousNext"
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    <img src="assets/images/table/previous.svg" alt="previous" />
                    <span>Previous</span>
                  </button>
                  <span>{currentPage}</span>
                  <button
                    className="previousNext"
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage ===
                      (Number.isInteger(dataDisplay?.length / entriesPerPage) ?
                        Number(dataDisplay?.length / entriesPerPage) :
                        Math.ceil(dataDisplay?.length / entriesPerPage))}
                  >
                    <span>Next</span>
                    <img src="assets/images/table/next.svg" alt="next" />
                  </button>
                  <button
                    className="previousNext"
                    onClick={() => {
                      setCurrentPage(
                        Number.isInteger(dataDisplay?.length / entriesPerPage) ?
                          Number(dataDisplay?.length / entriesPerPage) :
                          Math.ceil(dataDisplay?.length / entriesPerPage));
                    }}
                    disabled={currentPage ===
                      (Number.isInteger(dataDisplay?.length / entriesPerPage) ?
                        Number(dataDisplay?.length / entriesPerPage) :
                        Math.ceil(dataDisplay?.length / entriesPerPage))}>
                    <span>Last</span>
                    <img src="assets/images/table/next.svg" alt="last" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </TableWrapper>
    </>
  );
};

export default UserManagement;
