import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { HomePage } from "./styles";

import InlineLoader from "../../../common/components/inline-loader/inline-loader.component";

function Home() {
  const navigate = useNavigate();
  
  useEffect(() => {
    navigate("/dashboard");
  });

  return (
    <HomePage>
      <InlineLoader></InlineLoader>
    </HomePage>
  );
}

export default Home;
