import React, { useState } from "react";
import Sidebar from "../../../common/sidebar/sidebar";
import RobotManagement from "../../../components/management/robotManagement/robotManagement";
import { ManageUserWrapper } from "../user-management/style";

const ManageRobot = () => {
  const [layout, setLayout] = useState("");

  const handburger = (arg) => {
    setLayout(arg);
  };
  return (
    <div className={layout ? "mainWrapper collapsed-layout" : "mainWrapper"}>
      <ManageUserWrapper>
        <div>
          <Sidebar action={handburger} />
        </div>
        <div>
          <RobotManagement />
        </div>
      </ManageUserWrapper>
    </div>
  );
};

export default ManageRobot;
