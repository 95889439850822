import { styled } from "styled-components";

export const UsernameIconWrapper = styled.div`
  .profileInitial {
    width: 40px;
    height: 40px;
    background: #32cdc6;
    border-radius: 50%;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #212036;
  }
`;
