import { styled } from "styled-components";

export const RobotsListModalWrapper = styled.div`
  .editModal {
    min-height: 400px !important;
    top: 25vh;
  }

  .topPartPadding {
    padding-top: 0px !important;
    padding-bottom: 34px !important;
  }

  .data_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    gap: 24px;
    padding: 24px 16px;
    background: #212036;
    border-radius: 5px;
  }

  .data_body {
    border-radius: 5px;
    background: #2c2b41;
    padding: 10px 20px 10px 20px;
    color: #99a2ac;
  }

  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px; 
  }

  .cencel-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #212036;
    border: 1px solid #338bc2;
    box-shadow: 0px 0px 10px rgba(75, 255, 247, 0.5);
    border-radius: 50px;
    padding: 16px 34.5px; 
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #ffffff;
    height: 32px; 
    width: fit-content;
  }
}
`;
