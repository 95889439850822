import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ModalWrapper } from "../styleSmallModal";
import { useDispatch } from "react-redux";
import { deleteUser } from "../../../provider/features/user-management/user-management.slice";

const DeleteUserModal = ({ show, closeModal, user, getUsersData }) => {
  const dispatch = useDispatch();
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        closeModal();
      }
    };
    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  });

  const handleSubmit = async () => {
    const resultAction = await dispatch(deleteUser(user?.user_id));

    if (deleteUser.fulfilled.match(resultAction)) {
      setSuccessMessage("Deleted user successfully!");
      setTimeout(() => {
        closeModal();
        getUsersData();
      }, 1500);
    }
  };

  return (
    <Modal show={show} onHide={closeModal}>
      <ModalWrapper>
        <div className="modal">
          <div className="topPart">
            <div className="user">
              <div className="userIconDiv">
                <img
                  src="/assets/images/modal/deleteUser.svg"
                  className="userIcon"
                  alt="Delete User"
                />
              </div>
              <div className="details">
                <div className="first">Delete User</div>
              </div>
            </div>
            <div className="crossIconDiv" onClick={closeModal}>
              <img
                src="/assets/images/modal/cross.svg"
                className="crossIcon"
                alt="Close"
              />
            </div>
          </div>
          <div className="bottomPart">
            <div className="content">
              <div className="confirmationMessage">
                Deleting this user will permanently erase all of their data and revoke access to services. 
                Do you want to proceed?
              </div>
              <div className="buttonWrapper">
                <button
                  type="button"
                  className="cencel-btn"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  Cancel
                </button>
                <button type="submit" className="delete-btn" onClick={handleSubmit}>
                  Delete
                </button>
              </div>
              {successMessage &&
                <div className="success-text">
                  {successMessage}
                </div>
              }
            </div>
          </div>
        </div>
      </ModalWrapper>
    </Modal>
  );
}

export default DeleteUserModal;