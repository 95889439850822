import { useDispatch, useSelector } from "react-redux";
import { changePassword, logout } from "../../../provider/features/auth/auth.slice";

export const useChangePassword = () => {
  const dispatch = useDispatch();
  const { isLoading, isSuccess, isError, message } = useSelector((state) => state.auth.changePassword);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const formData = new FormData();
      formData.append("password", values.password);
      formData.append("repeat_password", values.confirmPassword);

      const response = await dispatch(changePassword({ formData }));
      if (response.meta.requestStatus === "fulfilled") {
        await dispatch(logout());
      }

    } catch (error) {
      console.error("Change password error:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return {
    isLoading,
    isSuccess,
    isError,
    message,
    handleSubmit,
  };
};