import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import organizationServices from './organization.service';

const initialState = {
  organizationList: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
  },
};

export const listOrganizations = createAsyncThunk(
  'organization/listOrganizations',
  async (params, thunkAPI) => {
    try {      
      const response = await organizationServices.listOrganizations(params);
            
      return response.data; 
    } catch (error) {
      console.error("Service call failed, error:", error);
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

export const getOrganization = createAsyncThunk(
  'organization/getOrganization',
  async ({params, successCallBack}, thunkAPI) => {
    try {      
      const response = await organizationServices.listOrganizations(params);
            
      return successCallBack(response.data); 
    } catch (error) {
      console.error("Service call failed, error:", error);
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

export const organizationSlice = createSlice({
  name: 'organizationSlice',
  initialState,
  reducers: {
    reset: (state) => {
      state.organizationList = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: '',
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listOrganizations.pending, (state) => {        
        state.organizationList.isLoading = true;
        state.organizationList.message = '';
        state.organizationList.isError = false;
        state.organizationList.isSuccess = false;
        state.organizationList.data = null;
      })
      .addCase(listOrganizations.fulfilled, (state, action) => {        
        state.organizationList.isLoading = false;
        state.organizationList.isSuccess = true;
        state.organizationList.data = action.payload;
      })
      .addCase(listOrganizations.rejected, (state, action) => {        
        state.organizationList.message = action.payload;
        state.organizationList.isLoading = false;
        state.organizationList.isError = true;
        state.organizationList.data = null;
      })
      .addCase(getOrganization.pending, (state) => {        
        state.organizationList.isLoading = true;
        state.organizationList.message = '';
        state.organizationList.isError = false;
        state.organizationList.isSuccess = false;
        state.organizationList.data = null;
      })
      .addCase(getOrganization.fulfilled, (state, action) => {        
        state.organizationList.isLoading = false;
        state.organizationList.isSuccess = true;
        state.organizationList.data = action.payload;
      })
      .addCase(getOrganization.rejected, (state, action) => {        
        state.organizationList.message = action.payload;
        state.organizationList.isLoading = false;
        state.organizationList.isError = true;
        state.organizationList.data = null;
      });
  },
});

export const { reset } = organizationSlice.actions;

export default organizationSlice.reducer;
