import { Field, Form, Formik } from "formik";
import React, { useState, useRef, useEffect } from "react";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import { ChangePasswordWrapper } from "./changePasswordStyle";
import { changePassword } from "../../../provider/features/auth/auth.slice";
import { putMe } from "../../../provider/features/user-management/user-management.slice";
import { useDispatch } from "react-redux";
import { AccountModalWrapper } from "../style3";
import { ChangePasswordSchema } from "../../../components/input-fields/input-fields";

const EditAccountSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim() // Removes leading and trailing whitespace
    .strict(true) // Ensures the value is exactly what's entered, after trimming
    .min(1, "Cannot be empty or whitespace") // Prevents empty after trimming
    .required("Required"),
  lastName: Yup.string()
    .trim()
    .strict(true)
    .min(1, "Cannot be empty or whitespace")
    .required("Required")
});

const ChangePasswordModal = ({ show, action, data }) => {

  const [messagePassword, setMessagePassword ] = useState("");
  const [messageUser, setMessageUser ] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const dispatch = useDispatch();

  const handleModalClose = () => {
    action();
  };

  const handleChangePasswordSubmit = async (values, resetForm) => {
    const formData = new FormData();
    formData.append("password", values && values.newPassword);
    formData.append("repeat_password", values && values.verifyPassword);
    const response = await dispatch(changePassword({ formData }));
  
    setMessagePassword(response?.payload?.message);
    if (response?.meta?.requestStatus === 'fulfilled') {
      resetForm();
    }
  };

  const handleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  const handleEditAccountSubmit = async (values) => {
    const formData = new FormData();
    formData.append("email", data?.email);
    formData.append("first_name", values && values.firstName);
    formData.append("last_name", values && values.lastName);
    formData.append("user_code", data?.user_code);
    formData.append("is_key_user", data?.is_key_user);
    formData.append("two_fa_web_required", values && values.twofa);
    const response = await dispatch(putMe({ formData }));
    
    if(response?.payload?.status === 200) {
      setMessageUser("Account updated successfully.");
    }
    else {
      setMessageUser("A problem occurred, please try again.")
    }
  };

  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const tfaRef = useRef(null);
  const roleRef = useRef(null);
  const accountSaveRef = useRef(null);
  const newPasswordRef = useRef(null);
  const confirmNewPasswordRef = useRef(null);
  const passwordSaveRef = useRef(null);
  const closeRef = useRef(null);

  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      switch (document.activeElement) {
        case firstNameRef.current:
          lastNameRef.current.focus();
          break;
        case lastNameRef.current:
          accountSaveRef.current.focus();
          break;
        case accountSaveRef.current:
          newPasswordRef.current.focus();
          break;
        case newPasswordRef.current:
          confirmNewPasswordRef.current.focus();
          break;
        case confirmNewPasswordRef.current:
          passwordSaveRef.current.focus();
          break;
        case passwordSaveRef.current:
          closeRef.current.focus();
          break;
        default:
          firstNameRef.current.focus();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  
  return (
    <Modal show={show} onHide={handleModalClose} backdrop="static">
      <AccountModalWrapper>
        <ChangePasswordWrapper>
          <div className="modal">
            <div className="topPart">
              <div className="user">
                <div className="userIconDiv">
                  <img
                    src="/assets/images/modal/editUser.svg"
                    className="userIcon"
                    alt="Change Password"
                  />
                </div>
                <div className="details">
                  <span className="first">Edit Account</span>
                  <span className="second">Change the information of your account</span>
                </div>
              </div>
              <div className="crossIconDiv" onClick={handleModalClose}>
                <img
                  src="/assets/images/modal/cross.svg"
                  className="crossIcon"
                  alt="Close"
                />
              </div>
            </div>
            <div style={{display: "flex"}}>
            <Formik
              initialValues={{
                firstName: data?.first_name,
                lastName: data?.last_name,
                twofa: data?.two_fa_web_required,
                userRole: data?.is_key_user ? "Key" : "Normal",
              }}
              validationSchema={EditAccountSchema}
              onSubmit={(values) => {
                values.status = values.status ? "Active" : "Inactive";
                handleEditAccountSubmit(values);
              }}
            >
              {({ errors, touched, values, setFieldValue }) => (
                <Form className="form_wrapper form-left">
                  <div className="field">
                    <label className="fieldLabels">First Name</label>
                    <Field
                      name="firstName"
                      type="text"
                      className={`text-field form-control mb-2 ${touched.firstName && errors.firstName ? "error" : ""}`}
                      placeholder="First Name"
                      innerRef={firstNameRef}
                    />
                    {errors.firstName && touched.firstName ? (
                      <div className="error-text">{errors.firstName}</div>
                    ) : null}
                  </div>

                  <div className="field">
                    <label className="fieldLabels">Last Name</label>
                    <Field
                      name="lastName"
                      type="text"
                      className={`text-field form-control mb-2 ${touched.lastName && errors.lastName ? "error" : ""}`}
                      placeholder="Last Name"
                      innerRef={lastNameRef}
                    />
                    {errors.lastName && touched.lastName ? (
                      <div className="error-text">{errors.lastName}</div>
                    ) : null}
                  </div>

                  <div className="field">
                    <label className="fieldLabels">Two-Factor Authentication</label>
                    <div className="switch-toggle">
                      <input
                        type="checkbox"
                        id="toggle2fa"
                        disabled="true"
                        checked={values.twofa}
                        onChange={() => setFieldValue("twofa", !values.twofa)}
                        ref={tfaRef}
                      />
                      <label className="toggleLabel" htmlFor="toggle2fa">
                        <span className="toggleText">{values.twofa ? "Active" : "Inactive"}</span>
                      </label>
                    </div>
                  </div>
                  {data?.is_key_user && 
                    <div className="field">
                      <label className="fieldLabels">Role</label>
                      <div className="role-container">
                        <Field
                          as="select"
                          name="userRole"
                          disabled="true"
                          className={`form-select form-select-sm text-field mb-2`}
                          innerRef={roleRef}
                        >
                          <option value="" disabled>
                            Select role
                          </option>
                          <option value="Key">Key user</option>
                          <option value="Normal">Normal user</option>
                        </Field>
                      </div>
                      {errors.role && touched.role ? (
                        <div className="error-text">{errors.role}</div>
                      ) : null}
                    </div>
                  }
                  <div className="bottom">

                    <button type="submit" className="submit-btn" ref={accountSaveRef}>
                      Save
                    </button>
                  </div>

                  <div>{messageUser}</div>
                </Form>
              )}
            </Formik>

            <Formik
              initialValues={{
                newPassword: '',
                verifyPassword: '',
              }}
              validationSchema={ChangePasswordSchema}
              onSubmit={(values, { resetForm }) => {
                values.status = values.status ? "Active" : "Inactive";
                handleChangePasswordSubmit(values, resetForm);
              }}
            >
              {({ errors, touched, values, setFieldValue }) => (
                <Form className="form_wrapper form-right">
                  
                  <div className="form-title">Update your password</div>
                  <div className="field mt-4">
                    <label className="fieldLabels">New Password</label>
                    <div className="password-input">
                      <Field
                        name="newPassword"
                        type={showPassword ? "text" : "password"}
                        className={`text-field form-control mb-2 ${touched.newPassword && errors.newPassword ? "error" : ""}`}
                        placeholder="New Password"
                        innerRef={newPasswordRef}
                      />
                      <img
                        src="/assets/images/auth/eye.svg"
                        className="eye-icon"
                        width="14.41px"
                        height="10.67px"
                        alt=""
                        onClick={handleShowPassword}
                      />
                    </div>
                    {errors.newPassword && touched.newPassword ? (
                      <div className="error-text">{errors.newPassword}</div>
                    ) : null}
                  </div>

                  <div className="field">
                    <label className="fieldLabels">Confirm New Password</label>
                    <div className="password-input">
                      <Field
                        name="verifyPassword"
                        type={showConfirmPassword ? "text" : "password"}
                        className={`text-field form-control mb-2 ${touched.verifyPassword && errors.verifyPassword ? "error" : ""}`}
                        placeholder="Confirm New Password"
                        innerRef={confirmNewPasswordRef}
                      />
                      <img
                        src="/assets/images/auth/eye.svg"
                        className="eye-icon"
                        width="14.41px"
                        height="10.67px"
                        alt=""
                        onClick={handleShowConfirmPassword}
                      />
                    </div>
                    {errors.verifyPassword && touched.verifyPassword ? (
                      <div className="error-text">{errors.verifyPassword}</div>
                    ) : null}
                  </div>
                  
                  <div className="bottom margin-top">
                    <button type="submit" className="submit-btn" ref={passwordSaveRef}>
                      Save
                    </button>
                  </div>

                  <div>{messagePassword}</div>
                </Form>
              )}
            </Formik>
            </div>
            <div className="bottom">
              <button
                type="button"
                className="cencel-btn"
                onClick={handleModalClose}
                ref={closeRef}
              >
                Close
              </button>
            </div>
          </div>
        </ChangePasswordWrapper>
      </AccountModalWrapper>
    </Modal>
  );
};

export default ChangePasswordModal;
