import React, { useEffect } from "react";
import InputFields from "../../../components/input-fields/input-fields";
import { AuthWrapper } from "../style";
import useResetPassword from "./use-reset-password.hook";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { ResetPasswordSchema } from "../../../components/input-fields/input-fields";

const ResetPassword = () => {
  const navigate = useNavigate();

  const {
    showPassword,
    showConfirmPassword,
    handleShowPassword,
    handleShowConfirmPassword,
    handleResetPassword,
    signed_pk,
    signed_pw_reset_request_at,
    resetPasswordSuccessful,
  } = useResetPassword();

  useEffect(() => {
    if (!signed_pk || !signed_pw_reset_request_at) {
      navigate("/generic-error");
    }
  }, [signed_pk, signed_pw_reset_request_at, navigate]);

  return (
    <AuthWrapper>
      <div className="login">
        <div className="authForm">
          {!resetPasswordSuccessful ? (
            <>
              <div className="brand">
                <img
                  src="/assets/images/auth/logo.svg"
                  className="logoImg"
                  alt=""
                />
                <span className="companyName">ATOMEUS</span>
              </div>

              <div className="loginHeadings">
                <span className="firstHeading">Reset Password</span>
                <span className="secondHeading">
                  Create a new password to regain access to your account.
                </span>
              </div>

              <Formik
                initialValues={{
                  newPassword: "",
                  confirmPassword: ""
                }}
                validationSchema={ResetPasswordSchema}
                onSubmit={handleResetPassword}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="formFields">
                      <div className="fields">
                        <div className="fieldLabel">New Password</div>
                        <div className="password">
                          <InputFields
                            type={showPassword ? "text" : "password"}
                            name="newPassword"
                            placeholder="Password"
                          />
                          <img
                            src="/assets/images/auth/eye.svg"
                            className="eyeImg"
                            width="14.41px"
                            alt=""
                            heigt="10.67px"
                            onClick={() => handleShowPassword(showPassword)}
                          />
                        </div>
                      </div>

                      <div className="fields">
                        <div className="fieldLabel">Confirm Password</div>
                        <div className="password">
                          <InputFields
                            type={showConfirmPassword ? "text" : "password"}
                            name="confirmPassword"
                            placeholder="Confirm Password"
                          />
                          <img
                            src="/assets/images/auth/eye.svg"
                            className="eyeImg"
                            alt=""
                            width="14.41px"
                            heigt="10.67px"
                            onClick={() =>
                              handleShowConfirmPassword(showConfirmPassword)
                            }
                          />
                        </div>
                      </div>

                      <div className="bottomArea">
                        <button type="submit" className="submit-btn">
                          Confirm
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </>) : (
            <div className="confirmation-wrapper">
              <div className="confirmation-message">Your password has been reset successfully!</div>
              <button
                className="go-to-login-btn"
                onClick={() => navigate("/login")}
              >
                Go to Login
              </button>
            </div>
          )}
        </div>
      </div>
    </AuthWrapper>
  );
};

export default ResetPassword;
