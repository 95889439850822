import api from "../../../common/utils/api";

const getUserdata = async () => {
  const response = await api().get("/v0/policy/list");
  return response.data;
};

const managementService = {
  getUserdata,
};

export default managementService;
