import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ModalWrapper } from "../style";
import { ViewModalWrapper } from "../viewModal";
import { TableWrapper } from "../../management/style";
import DeleteRightModal from "./delete";

const DeleteAssignedUsersModal = ({ show, closeModal, users, robotId, updateUserListForRobot }) => {
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [selectedRightId, setSelectedRightId] = useState(null);
    const [displayUsers, setDisplayUsers] = useState([]);

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === "Escape") {
                closeModal();
            }
        };
        window.addEventListener("keydown", handleEsc);
        return () => {
            window.removeEventListener("keydown", handleEsc);
        };
    }, [closeModal]);

    useEffect(() => {
        setDisplayUsers(users);
    }, [users]);

    const openConfirmationModal = (rightId) => {
        setSelectedRightId(rightId);
        setShowConfirmationModal(true);
    };

    const closeConfirmationModal = () => {
        setSelectedRightId(null);
        setShowConfirmationModal(false);
    };

    const updateUserListForRobotLocal = (robotId, rightId) => {
        const newDisplayUsers = JSON.parse(JSON.stringify(displayUsers));
        const index = newDisplayUsers.findIndex((user) => user.rightId === rightId);
        newDisplayUsers.splice(index, 1);
        setDisplayUsers(newDisplayUsers);
        updateUserListForRobot(robotId, rightId);
    };

    return (
        <>
            <Modal show={show && !showConfirmationModal} onHide={closeModal} backdrop="static">
                <ModalWrapper>
                    <ViewModalWrapper>
                        <TableWrapper>
                        <div className="modal viewModal">
                            <div className="topPart" style={{ paddingTop: '0px', paddingBottom: '20px' }}>
                                <div className="user">
                                    <div className="userIconDiv">
                                        <img
                                            src="/assets/images/modal/deleteUser.svg"
                                            className="userIcon"
                                            alt="Delete Users"
                                        />
                                    </div>
                                    <div className="details">
                                        <span className="first">Free up a seat</span>
                                        <span className="second">Remove users who have access to the robot</span>
                                    </div>
                                </div>
                                <div className="crossIconDiv mt-n-40" onClick={closeModal}>
                                    <img
                                        src="/assets/images/modal/cross.svg"
                                        className="crossIcon"
                                        alt="Close"
                                    />
                                </div>
                            </div>
                                <div className="bottomPart">
                                    <table className="table table-striped" style={{ width: '100%' }}> 
                                        <thead>
                                            <tr>
                                                <th>First Name</th>
                                                <th>Last Name</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {displayUsers.map((user, index) => (
                                                <tr className="tableRows" key={index}>
                                                    <td>{user.firstName}</td>
                                                    <td>{user.lastName}</td>
                                                    <td>
                                                        <img
                                                            src="/assets/images/table/delete.svg"
                                                            className="iconStyle"
                                                            title="Remove User"
                                                            alt="Remove User"
                                                            onClick={() => openConfirmationModal(user.rightId)}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            <div className="buttonWrapper">
                                <button
                                    type="button"
                                    className="cancel-btn"
                                    onClick={closeModal}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                        </TableWrapper>
                    </ViewModalWrapper>
                </ModalWrapper>
            </Modal>

            {showConfirmationModal && (
                <DeleteRightModal
                    show={showConfirmationModal}
                    closeModal={closeConfirmationModal}
                    rightId={selectedRightId}
                    robotId={robotId}
                    updateUserListForRobot={updateUserListForRobotLocal}
                />
            )}
        </>
    );
};

export default DeleteAssignedUsersModal;
