import React from "react";
import { ErrorContainer, ErrorButton, ErrorMessage } from "./style";

const GenericError = () => {
  return (
    <>
      <ErrorContainer>
        <ErrorMessage>We’re sorry, something didn’t work as expected.</ErrorMessage>
        <ErrorButton href="/">Back to homepage</ErrorButton>
      </ErrorContainer>
    </>
  );
};

export default GenericError;