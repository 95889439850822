import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { React, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Bar } from "react-chartjs-2";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { options_NPS } from "./chart-constant";
import { ChartWrapper } from "./style";
import { useDashboard } from "./use-charts-hook";
import BeatLoader from "react-spinners/BeatLoader";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const Chart = ({ user_id, bot_id, targetRef }) => {
  const {
    loadingChart,
    dataFTE,
    dataNPS,
    cumulativeNPS,
    chartUnit,
    setChartUnit,
    chartOptions,
    monthlyDataFTE } = useDashboard({ user_id, bot_id });


  const hideZeroTimeValues = (data) => {
    const modifiedDatasets = data.datasets.map(dataset => ({
      ...dataset,
      data: dataset.data.map(value => (parseFloat(value) > 0 ? value : null)), // Replace 0 or non-positive values with null
    }));
  
    return {
      ...data,
      datasets: modifiedDatasets,
    };
  }

  useEffect(() => {
    const handleResize = () => {
      if (targetRef.current && targetRef.current.chartInstance) {
        targetRef.current.chartInstance.resize(); // Chart.js 2.x instance
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  if (loadingChart) {
    return (
      <div className="spinner-container">
        <BeatLoader className="beat-loader" color="#123abc" />
      </div>
    );
  }

  const getTooltip = () => {
    return "This graph shows the Total Time Saved KPI over the past 12 months. You can set the unit of measure to minutes, hours or euros (assuming an average cost of €30 per hour). You can disable/enable a robot in the graph by clicking on it. By hovering over the bar, you can see the robot for which the KPI is displayed.";
  }

  return (
    <ChartWrapper style={{height: 'auto'}}>
      <div className="containerClass">
        <div style={{ width: '100%', display: 'flex' }}>
          <div className="chartBorder" ref={targetRef}>
            <div className="top-side">
              <div className="heading-one">
                Productivity in {chartUnit}
                <img
                    src={`/assets/images/dashboard/help.svg`}
                    alt="Info"
                    className="info-icon"
                    title={getTooltip()}
                  />
              </div>
              <div className="dropdown-class">
                <Dropdown>
                  <Dropdown.Toggle>
                  <img
                    src="/assets/images/dashboard/calendar.svg"
                    className="calendar-icon"
                    alt=""
                  />
                  {chartUnit}
                  <img
                    src="/assets/images/dashboard/arrow-down.svg"
                    className="arrow-down-icon"
                    alt=""
                  />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => { setChartUnit('Hours'); }}>Hours</Dropdown.Item>
                    <Dropdown.Item onClick={() => { setChartUnit('Minutes'); }}>Minutes</Dropdown.Item>
                    <Dropdown.Item onClick={() => { setChartUnit('EUR'); }}>EUR</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="graph">
              <Bar options={chartOptions} data={hideZeroTimeValues(dataFTE)} />
            </div>
          </div>

          <div style={{ width: '2%' }}></div>

          <div className="chartBorder-right-side">
            <div style={{ width: '100%', display: 'flex' }}>
              <div className="heading-one" style={{ width: '50%' }}>Month</div>
              <div className="heading-one" style={{ width: '50%' }}>Time</div>
            </div>
            {monthlyDataFTE.map((info) => (
              <div style={{ width: '100%', display: 'flex' }}>
                <div className="monthly-data-sub">{info.month}</div>
                <div className="monthly-data-sub">{info.value}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="chartBorder-two" style={{ display: 'none' }}>
          <div className="leftPart">
            <div className="nps">NPS For Organization</div>

            <div style={{ width: 252, height: 252 }} className="progressBar">
              <CircularProgressbar
                value={cumulativeNPS}
                maxValue={1}
                text={`+${cumulativeNPS * 100}`}
              />
            </div>
          </div>
          <div className="bottom-graph">
            <div className="heading-one">
              Net Promoter Score (NPS) Over Time
            </div>
            <div className="graph-content">
              <Bar options={options_NPS} data={dataNPS} />
            </div>
          </div>
        </div>
      </div>
    </ChartWrapper>
  );
};

export default Chart;
