import React from "react";
import { Field, ErrorMessage, useField } from "formik";
import * as Yup from "yup";
import { ContactFormWrapper } from "./styles";

// same list of common passwords that is used 
// by the django backend https://github.com/django/django/blob/main/django/contrib/auth/common-passwords.txt.gz
// sorted for faster lookup 
import commonPasswords from './common_passwords.json';

export const isCommonPassword = (password, sortedArray) => {
  let left = 0;
  let right = sortedArray.length - 1;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);
    const midVal = sortedArray[mid];

    if (midVal === password) return true;
    if (midVal < password) left = mid + 1;
    else right = mid - 1;
  }

  return false;
};

export const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters long.")
    .max(100, "Password is too long.")
    .matches(/^(?![0-9]*$)/, "Password cannot be entirely numeric.")
    .test(
      'not-common-password',
      'Password is too common',
      (value) => !isCommonPassword(value, commonPasswords)
    )
    .required("Password is required"),
});

export const RegisterSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password must be at least 8 characters long.")
    .max(100, "Password is too long.")
    .matches(/^(?![0-9]*$)/, "Password cannot be entirely numeric.")
    .test(
      'not-common-password',
      'Password is too common',
      (value) => !isCommonPassword(value, commonPasswords)
    )
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], "Passwords must match")
    .required("Confirm Password is required"),
});

export const ChangePasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(8, "Password must be at least 8 characters long.")
    .max(100, "Password is too long.")
    .matches(/^(?![0-9]*$)/, "Password cannot be entirely numeric.")
    .test(
      'not-common-password',
      'Password is too common',
      (value) => !isCommonPassword(value, commonPasswords)
    )
    .required("New password is required"),
  verifyPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], "Passwords must match")
    .required("Confirm new password is required"),
});

export const ResetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string().min(8, "Password must be at least 8 characters long.")
    .max(100, "Password is too long.")
    .matches(/^(?![0-9]*$)/, "Password cannot be entirely numeric.")
    .test(
      'not-common-password',
      'Password is too common',
      (value) => !isCommonPassword(value, commonPasswords)
    )
    .required("Password is required"),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('newPassword'), null], "Passwords must match")
    .required("Confirm Password is required"),
});

const InputFields = ({ type, name, placeholder }) => {
  const [, meta] = useField(name);

  return (
    <ContactFormWrapper>
      <div className="field">
        <Field
          name={name}
          type={type}
          className={`email-field form-control mb-2 ${
            type === "email"
              ? "email-specific-class"
              : "password-specific-class"
          }
          ${
            meta.touched && meta.error ? "error-border" : ""
          }`}
          placeholder={placeholder}
        />
        <ErrorMessage name={name}>
          {(errMsg) => <div className="error-text">{errMsg}</div>}
        </ErrorMessage>
      </div>
    </ContactFormWrapper>
  );
};

export default InputFields;
