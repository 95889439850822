import { useState } from "react";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../../provider/features/auth/auth.slice";
import { useParams } from "react-router-dom";

function useResetPassword() {
  const { signed_pw_reset_request_at, signed_pk } = useParams();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [resetPasswordSuccessful, setResetPasswordSuccessful] = useState(false);

  const handleShowPassword = (state) => {
    setShowPassword(!state);
  };

  const handleShowConfirmPassword = (state) => {
    setShowConfirmPassword(!state);
  };

  const handleResetPassword = async (values) => {
    const { newPassword, confirmPassword } = values;
    const formData = new FormData();
    formData.append("password", newPassword);
    formData.append("repeat_password", confirmPassword);

    const response = await dispatch(resetPassword({ signed_pw_reset_request_at, signed_pk, formData }));
    if (response.payload.status === 200) {
      setResetPasswordSuccessful(true);
    }
  };

  return {
    showPassword,
    showConfirmPassword,
    handleShowPassword,
    handleShowConfirmPassword,
    handleResetPassword,
    signed_pw_reset_request_at,
    signed_pk,
    resetPasswordSuccessful,
  };
}

export default useResetPassword;
