import React from "react";
import { NoRecordFoundWrapper } from "./style";

const NoRecordFound = ({ msg = "No record found" }) => {
  return (
    <NoRecordFoundWrapper>
      <h6 className="mb-0 text-center">{msg}</h6>
    </NoRecordFoundWrapper>
  );
};

export default NoRecordFound;
