import React from "react";
import { Link } from "react-router-dom";
import { Form, Formik } from "formik";
import InputFields from "../../../components/input-fields/input-fields";
import { AuthWrapper } from "../style";
import { useLogin } from "./login.hook";
import CustomButton from "../../../common/components/custom-button/custom-button-component";
import InlineLoader from "../../../common/components/inline-loader/inline-loader.component";

const Login = () => {
  const {
    initialValues,
    showPassword,
    handleShowPassword,
    LoginSchema,
    onSubmit,
    auth,
    errorMessage,
  } = useLogin();

  return (
    <AuthWrapper>
      <div className="login">
        <div className="authForm">
          <div className="brand">
            <img
              src="/assets/images/auth/logo.svg"
              className="logoImg"
              alt=""
            />
            <span className="companyName">ATOMEUS</span>
          </div>

          <div className="loginHeadings">
            <span className="firstHeading">Welcome!</span>
            <span className="secondHeading">
              Enter your credentials
            </span>
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={LoginSchema}
            onSubmit={onSubmit}
          >
            {() => (
              <Form className="formFields">
                <div className="fields">
                  <label className="fieldLabel">Email Address</label>
                  <InputFields
                    type="email"
                    name="email"
                    placeholder="Email"
                  />
                </div>
                <div className="fields">
                  <label className="fieldLabel">Password</label>
                  <div className="password">
                    <InputFields
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="Password"
                    />
                    <img
                      src={`/assets/images/auth/${!showPassword ? "eye.svg" : "eye_slash.svg"}`}
                      className="eyeImg"
                      width={`${!showPassword ? "14.41px" : "17.41px"}`}
                      height={`${!showPassword ? "10.67px" : "13.67px"}`}
                      alt=""
                      onClick={handleShowPassword}
                    />
                  </div>
                </div>

                <div className="bottomArea">
                  <CustomButton
                    type="submit"
                    className="submit-btn"
                    text={!auth.isLoading && "Login"}
                    startIcon={auth.isLoading && <InlineLoader />}
                  />

                  {errorMessage && (
                    <div className="error-text" style={{ alignSelf: 'center' }}>{errorMessage}</div>
                  )}

                  <div>
                    <Link to="/forgot-password" className="forgotPassword">
                      Forgot password?
                    </Link>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </AuthWrapper>
  );
};

export default Login;
