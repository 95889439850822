import { UsernameIconWrapper } from "./style"

const UsernameIcon = ({ user }) => {
    // Use optional chaining to safely access first_name and last_name
    return (
      <UsernameIconWrapper>
      <div className="profileInitial">
          {user?.first_name?.slice(0, 1)?.toUpperCase() || ""}
          {user?.last_name?.slice(0, 1)?.toUpperCase() || ""}
      </div>
      </UsernameIconWrapper>
  );
}

export default UsernameIcon;