import { styled } from "styled-components";

export const AuthWrapper = styled.div`
	/* display: flex;
  flex-direction: column; */
	/* gap: 55rem; */

	.confirmation-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 20px;
		margin-bottom: -20px;

		.confirmation-message {
			font-weight: 500;
			text-align: center;
			font-size: 18px;
		}

		.go-to-login-btn {
			margin-top: 30px;
			width: 140px;
			background: #086a8f;
			border: 1px solid #338bc2;
			box-shadow: 0px 0px 10px rgba(75, 255, 247, 0.5);
			border-radius: 50px;
			font-weight: 400;
			font-size: 16px;
			line-height: 26px;
			color: #ffffff;
			height: 42px;
			text-transform: unset;
		}
	}

	.login {
		background: url(/assets/images/auth/background.jpg) no-repeat center center;
		background-size: cover;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100vh;
	}

	.change-password {
		background: url(/assets/images/auth/background.jpg) no-repeat center center;
		background-size: cover;
		display: flex;
		justify-content: center;
		height: 100vh;
	}

	.change-password-body {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-grow: 1;
	}


	.setup-2fa {
		background: url(/assets/images/auth/background.jpg) no-repeat center center;
		background-size: cover;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100vh;
	}

	.registration {
		background: url(/assets/images/auth/background.jpg) no-repeat center center;
		background-size: cover;
		display: flex;
		flex-direction: column;
		height: 100vh;
	}

	.registration-body {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-grow: 1;
	}

.setup-2fa {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f4f4f9;
  padding: 20px;
}

.brand {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.logoImg {
  width: 50px;
  height: auto;
  margin-right: 10px;
}

.companyName {
  font-size: 24px;
  font-weight: bold;
}

.heading {
  font-size: 22px;
  text-align: center;
  margin-bottom: 20px;
}

.twoFAmessages {
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding-top: 20px;
	padding-bottom: 20px;

	.error-text {
		font-size: 16px;
	}
	.success-text {
		font-size: 16px;
		color: green;
	}
}  

.resetWrapper {
	padding: 20px 10px 0px 10px;
	margin-bottom: -30px;

	.line {
		border: none;
		border-top: 2px solid #ccc;
		margin-bottom: 10px;
		border-color: #434344;	
	}

	.resetText {
		font-size: 11px;
		margin-bottom: 1px;
	}

	.resetLink {
		font-size: 11px;
		.link {
			color: #086a8f;
			text-decoration: none;
		}	
	}
}

.content-wrapper {
  justify-content: space-between;
  align-items: center;  

    .verificationCodeInputContainer {
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin-top: 100px;
		gap: 10px;

		.verificationDigitInput {
			width: 50px;
			height: 50px;
			text-align: center;
			background: white;
			border-radius: 6px;
			border: 1px solid #2c2b41;
			font-weight: 400;
			font-size: 20px;
			line-height: 24px;
			color: black;
			background: white;
			outline: none !important;
			background-image: none;
		}
	}
}

.info-wrapper {
  flex: 1;
  margin-right: 20px;
}

.infoText {
  font-size: 16px;
  line-height: 1.5;
  color: #333;
}

.twofaImgWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.setup-btn-wrapper {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.setup-btn {
  padding: 10px 20px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.setup-btn:hover {
  background-color: #0056b3;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
  border-radius: 8px;
  text-align: center;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

	.authForm {
		display: flex;
		flex-direction: column;
		padding: 24px 24px 45px 24px;
		gap: 40px;
		/* /* position: absolute; */
		width: 420px;
		/* left: calc(50% - 420px / 2);
    	top: calc(50% - 480px / 2);  */
		background: #212036;
		border-radius: 8px;
		margin: auto;

		.brand {
			display: flex;
			gap: 8px;
			align-items: center;
		}

		.brand .logoImg {
			width: 32px;
			height: 29px;
		}

		.brand .companyName {
			font-family: 'Inter';
			font-style: normal;
			font-weight: 700;
			font-size: 16px;
			line-height: 22px;
			letter-spacing: 0.06em;
			color: #f9fafb;
		}

		.welcomHeadings {
			display: flex;
			flex-direction: column;
			align-items: center;

			.first {
				font-weight: 700;
				font-size: 24px;
				line-height: 32px;
				text-align: center;
				color: #ffffff;
			}

			.second {
				font-weight: 400;
				font-size: 16px;
				line-height: 32px;
				text-align: center;
				color: #e0e0e0;
			}
		}

		.loginHeadings {
			display: flex;
			flex-direction: column;
			gap: 8px;

			.firstHeading {
				font-weight: 500;
				font-size: 20px;
				line-height: 140%;
				color: #f4f6f8;
			}

			.secondHeading {
				font-weight: 400;
				font-size: 16px;
				line-height: 19px;
				color: #99a2ac;
			}

			.secondHeadingAlt {
				font-weight: 400;
				font-size: 16px;
				line-height: 19px;
				color: #f4f6f8;
			}
		}

		.setupText {
			display: flex;
			flex-direction: column;
			justify-content: center;
			gap: 14px;

			.heading {
				font-weight: 500;
				font-size: 22px;
				line-height: 140%;
				color: #f4f6f8;
			}

			.infoText {
				text-align: center;
				margin-top: 20px;
				font-weight: 300;
				font-size: 17px;
				line-height: 19px;
				color: #f4f6f8;
				white-space: pre-line;
			}
		}

		.twofaImgWrapper {
			width: 100%;
			margin-top: 20px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}

		.setup-btn-wrapper {
			margin-top: 20px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.setup-btn {
				align-items: center;
				display: flex;
				background: #086a8f;
				border: 1px solid #338bc2;
				box-shadow: 0px 0px 10px rgba(75, 255, 247, 0.5);
				border-radius: 50px;
				padding: 12px 34.5px;
				font-weight: 400;
				font-size: 16px;
				line-height: 26px;
				color: #ffffff;
				height: 42px;
				font-size: 20px;
				width: fit-content;
			}
		}

		.formFields {
			display: flex;
			flex-direction: column;
			gap: 24px;
			.fields {
				display: flex;
				flex-direction: column;
				gap: 5px;

				.fieldLabel {
					font-weight: 600;
					font-size: 14px;
					line-height: 17px;

					color: #f9fafb;
				}
			}

			.password {
				position: relative;
				.eyeImg {
          			position: absolute;
					right: 0;
					top: 15px;
					left: 95%;
					transform: translateX(-50%);
				}
			}

			.bottomArea {
				display: flex;
				flex-direction: column;
				gap: 12px;
				.submit-btn {
					background: #086a8f;
					border: 1px solid #338bc2;
					box-shadow: 0px 0px 10px rgba(75, 255, 247, 0.5);
					border-radius: 50px;
					font-weight: 400;
					font-size: 16px;
					line-height: 26px;
					color: #ffffff;
					height: 42px;
					text-transform: unset;
				}
				.forgotPassword {
					float: right;
					font-weight: 700;
					font-size: 14px;
					line-height: 19px;
					text-align: center;
					color: #086a8f;
				}
			}
		}
	}
`;
