import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "./auth.service";

const initialState = {
  login: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  forgotPassword: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  logout: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  changePassword: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  register: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  userQRImage: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  user2FACode: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  userSavedToken: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  }
};

// Login user
export const login = createAsyncThunk(
  "auth/login",
  async ({ formData, successCallBack, callBackMessage }, thunkAPI) => {
    try {
      const response = await authService.login(formData);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

// Forgot password
export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async ({ formData, successCallBack, callBackMessage }, thunkAPI) => {
    try {
      const response = await authService.forgotPassword(formData);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

// Reset password
export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async ({ signed_pw_reset_request_at, signed_pk, formData, successCallBack, callBackMessage }, thunkAPI) => {
    try {
      const response = await authService.resetPassword(signed_pw_reset_request_at, signed_pk, formData);
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

// Logout
export const logout = createAsyncThunk(
  "auth/logout",
  async (thunkAPI) => {
    try {
      const response = await authService.logout();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

// Change password
export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async ({ formData, successCallBack, callBackMessage }, thunkAPI) => {
    try {
      const response = await authService.changePassword(formData);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

// Register user
export const registerUser = createAsyncThunk(
  "auth/registerUser",
  async ({ signedpk, formData }, thunkAPI) => {
    try {
      const response = await authService.registerUser(signedpk, formData);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

//Get QR code
export const getQRImage = createAsyncThunk(
  'userManagement/two-factor-auth',
  async (thunkAPI) => {
    try {
      const response = await authService.getQRImage();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

//Validate code for 2fa
export const validate2FACode = createAsyncThunk(
  'userManagement/validate-2FA',
  async (data , thunkAPI) => {
    try {
      const response = await authService.validate2FACode(data);
      if (response?.status === 200) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue({ payload: response?.data });
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

//Save QR code
export const saveQRToken = createAsyncThunk(
  'userManagement/save-2FAToken',
  async (thunkAPI) => {
    try {
      const response = await authService.saveQRToken();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.login = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.register = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // Login
      .addCase(login.pending, (state) => {
        state.login.isLoading = true;
        state.login.message = "";
        state.login.isError = false;
        state.login.isSuccess = false;
        state.login.data = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.login.isLoading = false;
        state.login.isSuccess = true;
        state.login.data = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.login.message = action.payload.message;
        state.login.isLoading = false;
        state.login.isError = true;
        state.login.data = null;
      })
      // Forgot password
      .addCase(forgotPassword.pending, (state) => {
        state.forgotPassword.isLoading = true;
        state.forgotPassword.message = "";
        state.forgotPassword.isError = false;
        state.forgotPassword.isSuccess = false;
        state.forgotPassword.data = null;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.forgotPassword.isLoading = false;
        state.forgotPassword.isSuccess = true;
        state.forgotPassword.data = action.payload;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.forgotPassword.message = action.payload.message;
        state.forgotPassword.isLoading = false;
        state.forgotPassword.isError = true;
        state.forgotPassword.data = null;
      })
      // Logout
      .addCase(logout.pending, (state) => {
        state.logout.isLoading = true;
        state.logout.message = "";
        state.logout.isError = false;
        state.logout.isSuccess = false;
        state.logout.data = null;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.logout.isLoading = false;
        state.logout.isSuccess = true;
        state.logout.data = action.payload;
      })
      .addCase(logout.rejected, (state, action) => {
        state.logout.message = action.payload.message;
        state.logout.isLoading = false;
        state.logout.isError = true;
        state.logout.data = null;
      })
      // Change password
      .addCase(changePassword.pending, (state) => {
        state.changePassword.isLoading = true;
        state.changePassword.message = "";
        state.changePassword.isError = false;
        state.changePassword.isSuccess = false;
        state.changePassword.data = null;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.changePassword.isLoading = false;
        state.changePassword.isSuccess = true;
        state.changePassword.data = action.payload;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.changePassword.message = action.payload.message;
        state.changePassword.isLoading = false;
        state.changePassword.isError = true;
        state.changePassword.data = null;
      })
      // Register
      .addCase(registerUser.pending, (state) => {
        state.register.isLoading = true;
        state.register.isError = false;
        state.register.isSuccess = false;
        state.register.message = "";
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.register.isLoading = false;
        state.register.isSuccess = true;
        state.register.data = action.payload;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.register.isLoading = false;
        state.register.isError = true;
        state.register.message = action.payload.message;
      })
      // getQRImage
      .addCase(getQRImage.pending, (state) => {
        state.userQRImage.isLoading = true;
        state.userQRImage.message = "";
        state.userQRImage.isError = false;
        state.userQRImage.isSuccess = false;
      })
      .addCase(getQRImage.fulfilled, (state, action) => {
        state.userQRImage.isLoading = false;
        state.userQRImage.isSuccess = true;
      })
      .addCase(getQRImage.rejected, (state, action) => {
        state.userQRImage.message = action.payload;
        state.userQRImage.isLoading = false;
        state.userQRImage.isError = true;
      })
      // getQRImage
      .addCase(validate2FACode.pending, (state) => {
        state.user2FACode.isLoading = true;
        state.user2FACode.message = "";
        state.user2FACode.isError = false;
        state.user2FACode.isSuccess = false;
      })
      .addCase(validate2FACode.fulfilled, (state, action) => {
        state.user2FACode.isLoading = false;
        state.user2FACode.isSuccess = true;
      })
      .addCase(validate2FACode.rejected, (state, action) => {
        state.user2FACode.message = action.payload;
        state.user2FACode.isLoading = false;
        state.user2FACode.isError = true;
      })
      // saveQRToken
      .addCase(saveQRToken.pending, (state) => {
        state.userSavedToken.isLoading = true;
        state.userSavedToken.message = "";
        state.userSavedToken.isError = false;
        state.userSavedToken.isSuccess = false;
      })
      .addCase(saveQRToken.fulfilled, (state, action) => {
        state.userSavedToken.isLoading = false;
        state.userSavedToken.isSuccess = true;
      })
      .addCase(saveQRToken.rejected, (state, action) => {
        state.userSavedToken.message = action.payload;
        state.userSavedToken.isLoading = false;
        state.userSavedToken.isError = true;
      });

  },
});

export const { reset } = authSlice.actions;

export default authSlice.reducer;
