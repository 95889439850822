import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ModalWrapper } from "../styleSmallModal";

const ConfirmMessageModal = ({ show, closeModal }) => {

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === "Escape") {
                closeModal();
            }
        };
        window.addEventListener("keydown", handleEsc);
        return () => {
            window.removeEventListener("keydown", handleEsc);
        };
    }, [closeModal]);

    return (
        <Modal show={show} onHide={closeModal}>
            <ModalWrapper>
                <div className="modal" style={{top: '25vh'}}>
                    <div className="bottomPart">
                        <div className="content">
                            <div className="confirmationMessage" style={{textAlign: 'center'}}>
                                Your message has been sent successfully.
                            </div>
                            <div className="buttonWrapper">
                                <button type="submit" className="delete-btn" onClick={closeModal}>
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalWrapper>
        </Modal>
    );
}

export default ConfirmMessageModal;
