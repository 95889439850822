import { configureStore } from '@reduxjs/toolkit';
import authReducer from './features/auth/auth.slice';
import dashboardReducer from './features/dashboard/dashboard.slice';
import landingpageReducer from './features/landingpage/landingpage.slice';
import managementReducer from './features/management/management.slice';
import usermanagement from './features//user-management/user-management.slice';
import robotmanagement from './features//robot-management/robot-management.slice';
import contactReducer from './features/contact/contact.slice'; 

const store = configureStore({
  reducer: {
    auth: authReducer,
    dashboard: dashboardReducer,
    landingpage: landingpageReducer,
    management: managementReducer,
    userManagement: usermanagement,
    robotManagement: robotmanagement,
    contact: contactReducer
  },
});

export default store;
