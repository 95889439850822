import { React } from "react";
import { useNavigate } from "react-router-dom";
import "react-circular-progressbar/dist/styles.css";
import { useDashboard } from "../charts/use-charts-hook";
import { HeaderWrapper } from "./style";
import BeatLoader from "react-spinners/BeatLoader";

const Header = ({ user_id }) => {
  const { loadingHeader, dashboardHeaderText } = useDashboard({ user_id });
  const navigate = useNavigate();

  if (loadingHeader) {
    return (
      <div className="spinner-container">
        <BeatLoader className="beat-loader" color="#123abc" />
      </div>
    );
  }

  return (
    <HeaderWrapper>
      <div className="mainDiv col-md-12">
        <div className="topDiv">
          <div className="rightPart" style={{ width: '100%' }}>
            <div style={{ width: '100%', display: 'flex' }}>
              <div style={{ width: '81.5%' }}>
                <span>{dashboardHeaderText}</span>
              </div>
              <div className="alignmentDiv" style={{ width: '18.5%' }}>
                {user_id &&
                  <button className="go-to-team-dashboard" onClick={() => navigate('/dashboard')}>
                    <span style={{fontSize: '12px'}}>Go to Team's Dashboard</span>
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </HeaderWrapper>
  );
};

export default Header;
