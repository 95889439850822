import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { ModalWrapper } from "../style";
import { ViewModalWrapper } from "../viewModal";
import { TableWrapper } from "../../management/style";

const ViewUsersModal = ({ show, closeModal, users }) => {
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        closeModal();
      }
    };
    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [closeModal]);

  return (
    <Modal show={show} onHide={closeModal} backdrop="static">
      <ModalWrapper>
        <ViewModalWrapper>
          <TableWrapper> 
            <div className="modal">
              <div className="topPart" style={{ paddingTop: '0px', paddingBottom: '20px' }}>
                <div className="user">
                  <div className="userIconDiv">
                    <img
                      src="/assets/images/dashboard/user.svg"
                      className="userIcon"
                      alt="View Users"
                    />
                  </div>
                  <div className="details">
                    <span className="first">View Users</span>
                    <span className="second">List of all users who have access to the robot</span>
                  </div>
                </div>
                <div className="crossIconDiv mt-n-40" onClick={closeModal}>
                  <img
                    src="/assets/images/modal/cross.svg"
                    className="crossIcon"
                    alt="Close"
                  />
                </div>
              </div>
              <div className="bottomPart">
                <table className="table table-striped" style={{ width: '100%' }}> 
                  <thead>
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user, index) => (
                      <tr className="tableRows" key={index}>
                        <td>{user.firstName}</td>
                        <td>{user.lastName}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="buttonWrapper">
                <button
                  type="button"
                  className="cancel-btn"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </div>
          </TableWrapper>
        </ViewModalWrapper>
      </ModalWrapper>
    </Modal>
  );
};

export default ViewUsersModal;
