import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import landingpage from "./landingpage.service";

const initialState = {
  getCard: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  
};

// landingpage user
export const getCard = createAsyncThunk(
  "landingpage/getCard",
  async ({ successCallBack }, thunkAPI) => {
    try {
      const response = await landingpage.getCard();
      if (response) {
        successCallBack(response);
        return response;
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);
export const landingpageSlice = createSlice({
  name: "landingpage",
  initialState,
  reducers: {
    reset: (state) => {
      state.getCard = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCard.pending, (state) => {
        state.getCard.isLoading = true;
        state.getCard.message = "";
        state.getCard.isError = false;
        state.getCard.isSuccess = false;
        state.getCard.data = null;
      })
      .addCase(getCard.fulfilled, (state, action) => {
        state.getCard.isLoading = false;
        state.getCard.isSuccess = true;
        state.getCard.data = action.payload;
      })
      .addCase(getCard.rejected, (state, action) => {
        state.getCard.message = action.payload.message;
        state.getCard.isLoading = false;
        state.getCard.isError = true;
        state.getCard.data = null;
      });
  },
});


export const { reset } = landingpageSlice.actions;

export default landingpageSlice.reducer;
