import styled from "styled-components";

export const ContactFormWrapper = styled.div`
  .field .email-field,
  .field .password-specific-class {
    border-radius: 8px;
    border: 1.5px solid var(--neutral-500, #64748B);
    background: #2C2B41;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    width: 100%;
    max-width: 372px;
    padding: 12px 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    outline: none !important;
    height: 40px;
  }

  .field .error-border {
    border-color: #CB1F34;
  }

    .submit-btn {
      background: #086a8f;
      border: 1px solid #338bc2;
      box-shadow: 0px 0px 10px rgba(75, 255, 247, 0.5);
      border-radius: 50px;
      padding: 12px 34.5px;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      color: #ffffff;
      width: fit-content;
    }
  }
`;