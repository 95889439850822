import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { ModalWrapper } from "../styleSmallModal";
import { useDispatch } from "react-redux";
import { deleteRight } from "../../../provider/features/right/right.slice";

const DeleteRightModal = ({ show, closeModal, rightId, robotId, updateUserListForRobot }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === "Escape") {
                closeModal();
            }
        };
        window.addEventListener("keydown", handleEsc);
        return () => {
            window.removeEventListener("keydown", handleEsc);
        };
    }, [closeModal]);

    const handleDelete = async () => {
        const response = await dispatch(deleteRight(rightId));
        if(response?.payload?.status === 200) {
            updateUserListForRobot(robotId, rightId);
        }
        closeModal();
    };

    return (
        <Modal show={show} onHide={closeModal}>
            <ModalWrapper>
                <div className="modal">
                    <div className="topPart">
                        <div className="user">
                            <div className="userIconDiv">
                                <img
                                    src="/assets/images/modal/deleteUser.svg"
                                    className="userIcon"
                                    alt="Delete User"
                                />
                            </div>
                            <div className="details">
                                <div className="first">Remove User</div>
                            </div>
                        </div>  
                        <div className="crossIconDiv" onClick={closeModal}>
                            <img
                                src="/assets/images/modal/cross.svg"
                                className="crossIcon"
                                alt="Close"
                            />
                        </div>
                    </div>
                    <div className="bottomPart">
                        <div className="content">
                            <div className="confirmationMessage">
                                Are you sure you want to remove this user's access to the robot?
                            </div>
                            <div className="buttonWrapper">
                                <button
                                    type="button"
                                    className="cencel-btn"
                                    onClick={closeModal}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="delete-btn"
                                    onClick={handleDelete} 
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalWrapper>
        </Modal>
    );
};

export default DeleteRightModal;