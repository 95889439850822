import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import contactServices from './contact.service';

const initialState = {
  contactSubmission: {
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
  },
};

export const createContact = createAsyncThunk(
  'contact/createContact',
  async ({ formData }, thunkAPI) => {
    try {
      const response = await contactServices.createContact(formData);
      return response.data; 
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error.response.data });
    }
  }
);

export const contactSlice = createSlice({
  name: 'contactSlice',
  initialState,
  reducers: {
    resetContactForm: (state) => {
      state.contactSubmission = {
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: '',
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createContact.pending, (state) => {
        state.contactSubmission.isLoading = true;
        state.contactSubmission.isError = false;
        state.contactSubmission.isSuccess = false;
        state.contactSubmission.message = '';
      })
      .addCase(createContact.fulfilled, (state, action) => {
        state.contactSubmission.isLoading = false;
        state.contactSubmission.isSuccess = true;
        state.contactSubmission.message = 'Contact created successfully';
      })
      .addCase(createContact.rejected, (state, action) => {
        state.contactSubmission.isLoading = false;
        state.contactSubmission.isError = true;
        state.contactSubmission.message = action.payload;
      });
  },
});

export const { resetContactForm } = contactSlice.actions;

export default contactSlice.reducer;
