import { useDispatch, useSelector } from "react-redux";
import { createContact, resetContactForm } from "../../../provider/features/contact/contact.slice";

export const useCreateContact = () => {
  const dispatch = useDispatch();
  const { isLoading, isSuccess, isError, message } = useSelector((state) => state.contact.contactSubmission);

  const handleSubmit = async (formData, setErrorMessage, callbackSuccess) => {
    const resultAction = await dispatch(createContact({ formData }));

    if (createContact.fulfilled.match(resultAction)) {
        setTimeout(() => {
          callbackSuccess();
          dispatch(resetContactForm());
        }, 3000);
    }
    else {
      setErrorMessage("An error has occured. Please contact support.");
    }
  };

  return {
    isLoading,
    isSuccess,
    isError,
    message,
    handleSubmit,
  };
};
